a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 12px;
}
@media (min-width: 1024px) {
  html {
    font-size: 14px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.navbar {
  justify-content: center;
  /*height: 75px;*/
  box-shadow: 0 2px 1.25rem 0 rgba(0, 0, 0, 0.2);
  background-color: #242a32;
  padding-left: 0;
}




