@font-face {
    font-family: "Avenir";
    src: url(./fonts/AvenirNext-Medium.woff) format('woff'), url(./fonts/AvenirNext-Medium.ttf) format("truetype"), url(./fonts/AvenirNext-Medium.eot) format('embedded-opentype');
}

@font-face {
    font-family: "Avenir-Regular";
    src: url(./fonts/avenir-next-regular.woff) format('woff'), url(./fonts/avenir-next-regular.ttf) format("truetype"), url(./fonts//avenir-next-regular.eot) format('embedded-opentype');
}

@font-face {
    font-family: "Avenir-DemiBold";
    src: url(./fonts/AvenirNext-DemiBold.woff) format('woff'), url(./fonts/AvenirNext-DemiBold.ttf) format("truetype"), url(./fonts/AvenirNext-DemiBold.eot) format('embedded-opentype');
}


* {
    font-family: "Avenir";
}

body, #root {
    background: #191f28;
    color: #fff;
}

a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    border: 0;
    outline: none;
}

*:active, *:focus, *:hover {
    border-style: none;
    text-decoration: none;
    border: 0;
    outline: none;
}

a:active, a:focus, a:hover, button:active, button:focus, button:hover {
    border-style: none;
    text-decoration: none;
    border: 0;
    outline: none;
    color: #ececec;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    font-family: "Avenir";
}

.RCM_Table_link {
    color: aqua;
    border: 0;
    box-shadow: none;
    cursor: pointer;
}

    .RCM_Table_link:hover, .RCM_Table_link:active, .RCM_Table_link:focus, .RCM_Table_link:visited {
        color: aqua;
        text-decoration: none;
        font-weight: 400;
        border: 0;
        box-shadow: none;
    }

.RCM_Brand {
    font-size: 1.5rem;
    padding-left: 0;
    margin: 0;
    vertical-align: middle;
    position: relative;
    top: 25%;
}

.RCM_User_Name_Wrapper {
    text-align: left;
}
/*@media (max-width: 1680px) and (min-width: 1367px) {
    html {
        font-size: 13px;
    }
}

@media (max-width: 1366px) {
    html {
        font-size: 13px;
    }
}

@media (max-width: 1200px) and (min-width: 640px) {
    html {
        font-size: 11px;
    }
}

@media (max-width: 640px) and (min-width: 320px) {
    html {
        font-size: 11px;
    }
}*/

*:active:focus {
    background-color: none;
    border: none;
    box-shadow: none;
    outline: none;
}

.RCM_container {
    background-color: #0e1319;
    padding-bottom: 4rem;
}

.RCM_wrapper {
    padding-top: 2rem;
}


.RCM_navContainer > div:first-child {
    width: 100%;
}

.RCM_navmenu.dropdown-menu.show {
    background-color: #252f39;
}

.RCM_navmenu .dropdown-item {
    color: #ececec;
    background-color: #252f39;
    padding: .5rem 2rem;
    margin-bottom: 1px;
    line-height: 26px;
    border-bottom: 1px solid #ececec;
}

    .RCM_navmenu .dropdown-item.active, .RCM_navmenu .dropdown-item:active {
        background-color: #13A5B0;
    }

.ant-menu-item .btn.btn-secondary, .ant-menu-item .btn.btn-secondary:hover, .ant-menu-item .btn.btn-secondary:active:focus,
.ant-menu-item-selected .btn.btn-secondary, .ant-menu-item-selected .btn.btn-secondary:hover, .ant-menu-item-selected .btn.btn-secondary:active:focus {
    top: 0;
    margin-top: 0;
    border-color: #001529;
    border-bottom: 0;
    color: rgba(255, 255, 255, 0.65);
    background-color: transparent;
}

    .ant-menu-item .btn.btn-secondary:hover, .ant-menu-item .btn.btn-secondary:active:focus,
    .ant-menu-item-selected .btn.btn-secondary, .ant-menu-item-selected .btn.btn-secondary:active:focus {
        color: #fff;
        background-color: transparent;
        border: none
    }

.RCM_Loginbutton {
    align-content: center;
    margin: 25%;
    margin-left: 50%;
    margin-top: 33%;
    background: #216ba5;
    color: #FFF;
    height: 3.125rem;
    width: auto;
    border: none;
}

.RCM_Logoutbutton {
    background: #216ba5;
    color: #FFF;
    height: auto;
    width: auto;
    border: none;
}
/*logo start */
/* .RCM_header_logo_img {
    background-image: url('./images/Logo-inverse.svg');
    width: 15.81rem;
    height: 2.188rem;
    border: none;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
} */

/* .RCM_logo_container {
    display: flex;
    justify-content: space-between;
} */

.RCM_font_sizing {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0rem;
    cursor: pointer
}

.RCM_font_sizing_disabled {
    color: #aab0b6;
    pointer-events: none;
    cursor: context-menu;
}

.RCM_font_sizing_small {
    font-size: 14px;
}

.RCM_font_sizing_big {
    font-size: 18px;
}

.RCM_font_sizing_normal {
    margin: 0.25rem;
}

.RCM_font_sizing_container > * {
    vertical-align: 0;
}

.RCM_font_sizing_container {
    text-align: right;
}

.RCM_fontsizing_wrapper {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
}

.RCM_font_sizing_container_hidden {
    visibility: hidden;
}
/*logo end*/
/*navbar style start*/
.RCM_sticky {
    z-index: 2;
    position: relative;
    width: 100%;
}

.sticky {
    z-index: 9998 !important;
}

.navbar-collapse {
    flex-grow: 1;
    color: #fff;
}

.navbar.navbar-expand-md.navbar-dark.bg-dark, .navbar.navbar-expand-lg.navbar-dark.bg-dark {
    background-color: #242a32 !important;
}

.navbar {
    justify-content: center;
    /*height: 3.75rem;*/
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.2);
    background-color: #242a32;
    padding-left: 0;
}

.navbar-toggler {
    margin-right: 0.625rem;
}

.RCM_menu_img {
    width: 1.45rem;
    height: 1.25rem;
    background-color: var(--color-white);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    margin-right: 0.6rem;
}

.RCM_noWrap {
    white-space: nowrap;
}

.RCM_Nav_li a:hover, .RCM_expandviewright a:hover {
    background-color: var(--color-white);
}

/* .RCM_Nav_Accounts {
    background-image: url('./images/Accounts.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Accounts, .RCM_Nav_Accounts:hover {
        background-image: url('./images/Accounts_hover.svg');
    } */

/* .RCM_Nav_wrapper.RCM_menu_active:hover .RCM_Nav_Accounts,
.RCM_menu_active .RCM_Nav_Accounts:hover,
.RCM_menu_active .RCM_Nav_Accounts {
    background-image: url('./images/Accounts_selected.svg');
}


.RCM_Nav_Collaboration {
    background-image: url('./images/Collaberation.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Collaboration, .RCM_Nav_Collaboration:hover {
        background-image: url('./images/Collaberation_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_Collaboration,
.RCM_menu_active .RCM_Nav_Collaboration:hover,
.RCM_menu_active .RCM_Nav_Collaboration {
    background-image: url('./images/Collaberation_selected.svg');
}

.RCM_Nav_LifeStyle {
    background-image: url('./images/Lifestyle.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_LifeStyle, .RCM_Nav_LifeStyle:hover {
        background-image: url('./images/Lifestyle_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_LifeStyle,
.RCM_menu_active .RCM_Nav_LifeStyle:hover,
.RCM_menu_active .RCM_Nav_LifeStyle {
    background-image: url('./images/Lifestyle_selected.svg');
}

.RCM_Nav_Trade {
    background-image: url('./images/Trade.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Trade, .RCM_Nav_Trade:hover {
        background-image: url('./images/Trade_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_Trade,
.RCM_menu_active .RCM_Nav_Trade:hover,
.RCM_menu_active .RCM_Nav_Trade {
    background-image: url('./images/Trade_selected.svg');
}

.RCM_Nav_Research {
    background-image: url('./images/Research.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Research, .RCM_Nav_Research:hover {
        background-image: url('./images/Research_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_Research,
.RCM_menu_active .RCM_Nav_Research:hover,
.RCM_menu_active .RCM_Nav_Research {
    background-image: url('./images/Research_selected.svg');
}

.RCM_Nav_Products {
    background-image: url('./images/Products.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Products, .RCM_Nav_Products:hover {
        background-image: url('./images/Products_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_Products,
.RCM_menu_active .RCM_Nav_Products:hover,
.RCM_menu_active .RCM_Nav_Products {
    background-image: url('./images/Products_selected.svg');
}

.RCM_Nav_Transfer {
    background-image: url('./images/Transfer.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Transfer, .RCM_Nav_Transfer:hover {
        background-image: url('./images/Transfer_hover.svg');
    }

.RCM_Nav_wrapper.RCM_menu_active:hover .RCM_Nav_Transfer,
.RCM_menu_active .RCM_Nav_Transfer:hover,
.RCM_menu_active .RCM_Nav_Transfer {
    background-image: url('./images/Transfer_selected.svg');
}

.RCM_Nav_Statements {
    background-image: url('./images/Statements.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Statements, .RCM_Nav_Statements:hover {
        background-image: url('./images/Statements_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_Statements,
.RCM_menu_active .RCM_Nav_Statements:hover,
.RCM_menu_active .RCM_Nav_Statements {
    background-image: url('./images/Statements_selected.svg');
}

.RCM_Nav_Help {
    background-image: url('./images/Help.svg');
}

    .RCM_Nav_wrapper:hover .RCM_Nav_Help, .RCM_Nav_Help:hover {
        background-image: url('./images/Help_hover.svg');
    }

.RCM_Nav_wrapper:hover .RCM_menu_active .RCM_Nav_Help,
.RCM_menu_active .RCM_Nav_Help:hover,
.RCM_menu_active .RCM_Nav_Help {
    background-image: url('./images/Help_selected.svg');
} */

.RCM_nav_print {
    margin: 0 1rem 0.8rem 0;
}


.REM_aaflex-list {
    list-style: none;
    color: var(--color-rich-electric-blue);
    text-align: right;
    cursor: pointer;
    font-size: 0.825rem;
}


    .REM_aaflex-list li {
        display: inline;
        border-left: 0.0625rem solid transparent;
        border-image: linear-gradient(to bottom, transparent 0%,transparent 25%, #4e545a 26%, #4e545a 75%, transparent 76%, transparent 100%);
        border-image-slice: 1;
    }

        .REM_aaflex-list li:first-child {
            border-left: none;
        }

    .REM_aaflex-list a {
        display: flex;
    }

        .REM_aaflex-list a.RCM_Nav_wrapper.RCM_menu_text {
            margin-top: 0.125rem;
        }

.RCM_nav_text {
    color: #ececec;
    height: 1rem;
    font-family: "Avenir-Regular";
    font-size: 0.75rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    padding-top: 0.25rem;
}

.REM_aaflex-list.navbar-nav .dropdown-menu.show {
    color: #ececec;
    background-color: #252f39;
}

    .REM_aaflex-list.navbar-nav .dropdown-menu.show a {
        color: #ececec;
        background-color: #252f39;
        text-decoration: none;
    }

.REM_aaflex-list.navbar-nav .dropdown-item:focus, .REM_aaflex-list.navbar-nav .dropdown-item:hover {
    color: #ececec;
    background-color: #252f39;
}

.REM_aaflex-list.navbar-nav button {
    color: #ececec;
    background-color: #242a32;
    border: none;
    padding: 0;
}

.RCM_accounts_only_wrapper {
    padding-left: 0;
    margin-left: -0.125rem;
}


.RCM_navmenu.dropdown-menu.show a {
    color: #ececec;
    font-size: 1.063rem;
    border-bottom: 0.5px solid var(--color-near-light-grey);
    line-height: 2.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #252f39;
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.29;
    letter-spacing: 0.5px;
    border-bottom: 0.0625rem solid #4e545a;
}

.RCM_navmenu.dropdown-menu.show {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

    .RCM_navmenu.dropdown-menu.show a:last-child {
        border-bottom: none;
    }

    .RCM_navmenu.dropdown-menu.show a button {
        background-color: #252f39;
        padding: 0.5rem 2rem;
        margin-bottom: 1px;
    }

.REM_aaflex-list .dropbottom .nav-item {
    padding: 0;
}

.REM_aaflex-list .dropbottom button {
    padding: 1.2rem 1.2rem 1.2rem 1.2rem;
}

.nav-item a.RCM_menu_text {
    padding: 1.2rem 1.2rem 1.2rem 1.2rem;
}

    .RCM_menu_text.RCM_menu_active, .nav-item a.RCM_menu_text.RCM_menu_active {
        border: solid 0.125rem #acefff;
        border-radius: 0;
    }

.REM_aaflex-list .dropbottom {
    border-left: 0.0625rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 25%, #4e545a 26%, #4e545a 75%, transparent 76%, transparent 100%);
    border-image-slice: 1;
}

    .REM_aaflex-list .dropbottom:first-child {
        border-left: none;
    }

.navbar {
    padding: 0;
}

.navbar-collapse {
    /*justify-content: center;*/
}

.dropdown .RCM_menu_text button li {
    display: flex;
}

.RCM_menu_text .btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none;
}

.RCM_Badge_introJS {
    display: none;
}

.RCM_Disable_Link {
    pointer-events: none;
}

.RCM_Nav_wrapper_Help {
    border: solid 0.125rem transparent;
    border-right: none;
}

    .RCM_Nav_wrapper_Help.RCM_Nav_wrapper_active {
        border: solid 0.125rem #acefff;
    }

    .RCM_Nav_wrapper_Help .RCM_Badge_introJS {
        background-color: #0E1319;
        color: #16a5b0;
        letter-spacing: 0.8px;
        display: inline;
        margin-right: 0.1875rem;
        pointer-events: auto;
    }

    .RCM_Nav_wrapper_Help a.RCM_menu_text {
        padding: 0rem 2rem 0.8rem 2rem;
    }

    .RCM_Nav_wrapper_Help .RCM_menu_text.RCM_menu_active, .RCM_Nav_wrapper_Help.nav-item a.RCM_menu_text.RCM_menu_active {
        border: none;
    }




/*navbar style end*/
/*tab view start*/

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background: transparent;
    color: aqua;
    border: 2px solid transparent;
    border-bottom: 2px solid #4e545a;
    margin-right: 0;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: transparent;
    color: aqua;
    border: 2px solid #4e545a;
    border-bottom: 2px solid transparent;
}
/*tab view end*/
/* slider start*/
/*.carousel-inner {
    height: 7.25rem;
}*/

.carousel-control-next, .carousel-control-prev {
    width: auto;
    align-items: unset;
    top: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-size: 100%, 100%;
    background-image: none;
    border: none;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
}

    .carousel-control-next-icon:before, .carousel-control-prev-icon:before {
        margin: 0 0.3125rem;
        display: inline-block;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        content: '';
    }

    .carousel-control-next-icon:before {
        border-left: 0.5rem solid #fff;
    }

    .carousel-control-prev-icon:before {
        border-right: 0.5rem solid #fff;
    }


.RCM_silderfirstitem {
    display: flex;
}

.carousel-item {
    position: relative;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
    padding: 0 1%;
}
/*slider end*/

/*header useraccount setting start*/

@media (min-width: 992px) {
    .col-lg-7.RCM_withFADetails {
        -ms-flex: 0 0 52.333333%;
        flex: 0 0 52.333333%;
        max-width: 52.333333%;
    }
}

@media (min-width: 1550px) {
    .col-lg-3.RCM_logosettingwrapper {
        -ms-flex: 0 0 21.5%;
        flex: 0 0 21.5%;
        max-width: 21.5%;
    }
}

@media (max-width: 1200px) {
    /*.col-lg-3.RCM_logosettingwrapper {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .col-lg-8.RCM_headerbalance {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .col-md-12.RCM_headerbalance {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }*/
}

@media (max-width: 1300) {
    .RCM_withFADetails div.RCM_fo_name {
        border-bottom: 0;
        line-height: unset;
    }
}

@media (max-width: 550px) {
    .RCM_withoutFADetails div.RCM_fo_name {
        border-bottom: 0;
        line-height: unset;
        margin: 0.3125rem 0 0;
    }

    .RCM_withoutFADetails .RCM_fo_value {
        margin: 0.3125rem 0 0;
    }
}

.RCM_headerbalance {
    padding: 0 1.563rem;
}

.RCM_headerWrapper {
    margin-bottom: 0.125rem;
}

.RCM_logosettingwrapper {
    border-right: 0.125rem solid #4e545a;
    margin-left: 2.688rem;
}

.RCM_logosettingwrapper_balances {
    border-right: 0.125rem solid #4e545a;
    margin: 0 1.344rem 0 0;
}

.RCM_accsettingWrapper {
    display: flex;
    margin-top: 1rem;
}

.RCM_balanceheader {
    margin-right: 2.5rem;
}

.RCM_usersettingwrapper {
    margin-left: 0.5rem;
    width: 100%;
}

.RCM_username {
    height: 1.375rem;
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0rem;
}

.RCM_settingWrapperUpper {
    display: flex;
    width: 100%;
    margin-top: -0.625rem;
}


.RCM_settingsWrapper {
    height: 0.6875rem;
    font-family: "Avenir";
    font-size: 0.62rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0625rem;
    color: #ececec;
    display: flex;
    text-transform: uppercase;
}

    .RCM_settingsWrapper div {
        letter-spacing: 0.0625rem;
        border-right: 0.125rem solid #ececec;
        margin-right: 0.375rem;
        padding-right: 0.375rem;
        cursor: pointer;
        margin-top: 0.125rem;
        padding-bottom: 0.625rem;
        font-family: 'Avenir-DemiBold';
    }

        .RCM_settingsWrapper div a {
            letter-spacing: 0.0625rem;
            cursor: pointer;
            font-family: 'Avenir-DemiBold';
        }

        .RCM_settingsWrapper div:last-child {
            border-right: none;
        }

.RCM_toggleWrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
}



.RCM_introbtn {
    border: none;
    /*
        width: 1.25rem;
    height: 1.25rem;
        background-image: url('./images/question_mark.svg');
        background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;*/
    margin-top: 0.3125rem;
    font-size: 0.7rem;
    cursor: pointer;
}

/*header useraccount setting end*/
/*RCM_PopoverUserIcon start*/
.popover.show.bs-popover-bottom {
    top: -0.75rem !important;
}

#RCM_PopoverUserIcon {
    cursor: pointer;
    border-bottom: 1rem solid transparent;
}

.RCM_popovermenu {
    color: #ececec;
    background-color: #252f39;
    margin: 0;
}

    .RCM_popovermenu li a {
        color: #ececec;
        font-size: 1.063rem;
        background-color: #252f39;
        font-size: 0.875rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.5;
        letter-spacing: 0.5px;
        background-color: #252f39;
        margin: 1rem 1.5rem;
    }

.RCM_popovermenu_item {
    border-bottom: 0.0625rem solid #4e545a;
}

    .RCM_popovermenu_item:last-child {
        border-bottom: 0.0625rem solid #252f39;
    }

.RCM_PopoverUserIconWrapper .popover {
    background-color: #252f39;
}

.RCM_PopoverUserIconWrapper .bs-popover-auto[x-placement^=bottom] > .arrow::after, .bs-popover-bottom > .arrow::after {
    border-bottom-color: #252f39;
}

.RCM_PopoverUserIconWrapper .popover:hover, .RCM_PopoverUserIconWrapper .popover:active, .RCM_PopoverUserIconWrapper .popover:focus {
    border: 0.0625rem solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline-style: solid;
    outline-color: transparent;
}

.RCM_PopoverUserIconWrapper .popover {
    border: 0.0625rem solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline-style: solid;
    outline-color: transparent;
}
/*RCM_PopoverUserIcon end*/
/*asset chart start*/
.RCM_pie {
    height: 25rem;
}

.RCM_barChart {
    height: 20rem;
}
/*asset chart end*/
/*asset chart start*/
.RCM_aachartWrapper {
    color: #fff;
    list-style-type: none;
    margin-bottom: 0.5rem;
}

.RCM_settingsWrapper .linkactive {
    border: solid 0.125rem #acefff;
    border-radius: 0;
}

ul li.RCM_aachartWrapper:last-child {
    margin: 0;
}

.RCM_aseetpct {
    font-size: 0.975rem;
    margin: 0 0 0 0.5rem;
    font-family: "Avenir";
    font-size: 0.8rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0625rem;
    color: #fff;
}

.RCM_assetName {
    margin: 0 0 0 0.5rem;
    font-family: "Avenir";
    font-size: 0.7125rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #fff;
}

.RCM_legent_Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.RCM_legent_height {
    max-height: 17rem;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    border-left: 0.125rem solid #4e545a;
    padding-left: 1.3rem;
}

@media (max-width: 1280px) {
    .RCM_legent_height {
        padding-left: 0.875rem;
    }
}

.RCM_aachart_0 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #13A5B0 11%, #13A5B0 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_1 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #B3E3F4 11%, #B3E3F4 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_2 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #7BC46C 11%, #7BC46C 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_3 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #C4E1B2 11%, #C4E1B2 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_4 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #B71F3A 11%, #B71F3A 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_5 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #EF4264 11%, #EF4264 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_6 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #653C93 11%, #653C93 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_7 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #B02C91 11%, #B02C91 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_8 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #F89938 11%, #F89938 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_9 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #F4B93F 11%, #F4B93F 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_aachart_10 {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #45bda9 11%, #45bda9 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

/*Estimated income chart*/
.RCM_legend_dividend {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #13A5B0 11%, #13A5B0 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_legend_income {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #C4E1B2 11%, #C4E1B2 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}

.RCM_legend_capitalgain {
    border-left: 0.25rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 10%, #B3E3F4 11%, #B3E3F4 90%, transparent 91%, transparent 100%);
    border-image-slice: 1;
}
/*asset cahrt end*/
/*tt chart start*/
#toptenholding .x.axis path, #toptenholding .y.axis path, #toptenholding .x.axis .tick line, #toptenholding .y.axis .tick line {
    /*color: transparent;*/
}

#toptenholding .x.axis .tick text, #toptenholding .y.axis .tick text, #projectincomechart .x.axis .tick text, #projectincomechart .y.axis .tick text {
    font-family: "Avenir";
    font-size: 0.625rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: right;
    color: #fff;
}

#toptenholding .y.axis text, #projectincomechart .y.axis text {
    font-family: "Avenir";
    font-size: 0.5rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: right;
    color: #fff;
}
/*chart tootip start*/
.tt_tooltip {
    background: #b0c4de;
    border: 0;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 1.25rem;
    padding: 0.125rem;
    pointer-events: none;
    position: absolute;
    text-align: center;
    width: 7.5rem;
}

.tt_tooltip {
    position: absolute;
    min-width: 9.231rem;
    height: 2.538rem;
    padding: 0 0 1.923rem 0;
    background-color: black;
    pointer-events: none;
    fill: #fff;
    color: #fff;
    text-anchor: middle;
    text-align: center;
    z-index: 9999;
}

    .tt_tooltip:before {
        border: solid;
        border-color: black transparent;
        border-width: 0.5692rem 0.7692rem 0 0.7692rem;
        top: 100%;
        content: "";
        left: 43%;
        position: absolute;
        z-index: 99;
    }

#RCM_mainTooltip {
    position: absolute;
    min-width: 9.231rem;
    height: 4.077rem;
    padding: 0 0 1.923rem 0;
    background-color: black;
    pointer-events: none;
    fill: #fff;
    color: #fff;
    text-anchor: middle;
    text-align: center;
    z-index: 9999;
    border-radius: 0.25rem;
}

    #RCM_mainTooltip:before {
        border: solid;
        border-color: black transparent;
        border-width: 0.7692rem 0.7692rem 0 0.7692rem;
        top: 100%;
        content: "";
        left: 43%;
        position: absolute;
        z-index: 99;
    }

    #RCM_mainTooltip.hidden {
        display: none;
    }


/*chart tooltip end*/
/* 
.RCM_left_arrow {
    background: url('./images/arrow-left.svg') no-repeat;
    height: 2rem;
    width: 2rem;
    padding: 0rem;
    border: 0rem;
}

.RCM_right_arrow {
    background: url('./images/arrow-right.svg') no-repeat;
    height: 1rem;
    width: 1rem;
    padding: 0rem;
    border: 0rem;
    background-size: auto 100%;
    margin-left: 40%;
}

.RCM_up_arrow {
    background: url('./images/arrow-up.svg') no-repeat;
    height: 0.7rem;
    width: 1rem;
    padding: 0rem;
    border: 0rem;
    background-size: auto 100%;
    margin-left: 40%;
}

.RCM_down_arrow {
    background: url('./images/arrow-down.svg') no-repeat;
    height: 0.6rem;
    width: 1rem;
    padding: 0rem;
    border: 0rem;
    background-size: auto 100%;
    margin-left: 40%;
} */

.react-bs-table-no-data {
    cursor: auto;
}
/*Header all account dropdown*/
.RCM_Accounts_dropDown_container {
    padding: 0;
}

ul.RCM_select_ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    background: var(--color-lighter-blue);
}

.RCM_rule_checkbox input[type="checkbox"] {
    position: absolute;
    left: -999em;
}

label.RCM_selectLabel.RCM_rule_checkbox {
    position: relative;
    cursor: pointer;
    margin-right:0;
}

.RCM_rule_checkbox input[type="checkbox"] + span {
    display: flex;
}

    .RCM_rule_checkbox input[type="checkbox"] + span.RCM_accounts_span {
        width: 5rem;
        word-break: break-all;
    }

    .RCM_rule_checkbox input[type="checkbox"] + span:before {
        border: 0.125rem solid #acefff;
        border-radius: 0.125rem;
        content: "\00a0";
        display: inline-block;
        font: 16px/1em sans-serif;
        height: 1rem;
        margin: 0.125rem 1em 0 0;
        padding: 0;
        vertical-align: top;
        width: 1rem;
        background: black;
        line-height: 0.6875rem;
        flex: none;
    }
.RCM_rule_checkbox input[type="checkbox"]:checked + span:before {
    background: #0e1319;
    color: #acefff;
    content: "\25A0";
    text-align: center;
    border: .0625rem solid #acefff;
}
.RCM_form_control.RCM_disabled_input {
   opacity:0.5
}
/*Check box css*/
/* Check box container */
.checkbox_container {
    display: block;
    position: relative;
    cursor: pointer;
    height: 1.2rem;
    width: 1.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #191f28;
    border: 1px solid #acefff;
}

    /* Hide the browser's default checkbox */
    .checkbox_container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0.15rem;
    left: 0.15rem;
    height: 0.8rem;
    width: 0.8rem;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
    background-color: #2a3641;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark {
    background-color: #acefff;
}

.checkbox_container input:disabled ~ .checkmark {
    cursor: not-allowed;
}

.checkbox_container input:disabled {
    cursor: not-allowed;
}

/*End Check box*/

.RCM_select_box.RCM_headre_select_box.dropdown .dropdown-toggle.btn-secondary {
    font-family: Avenir;
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #fff;
    width: 100%;
    height: 3.75rem;
}

.RCM_select_box.RCM_headre_select_box.dropdown .dropdown-menu {
    width: 100%;
    transform: translate3d(0, 0, 0) !important;
}

.RCM_select_box {
    text-align: center;
}

    .RCM_select_box .dropdown-menu {
        width: 14.69rem;
        border-radius: 0.25rem;
        background-color: #252f39;
        box-shadow: 0 0 0.25rem 0 rgba(0,0,0,0.12), 0 0.5rem 0.5rem 0 rgba(0,0,0,0.24);
        border: none;
    }

.RCM_selectBox_groupingContainer {
    background-color: #2a3641;
    box-shadow: 0 0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    width: 100%;
    border-radius: 0;
    text-align: left;
    padding: 1rem 1.18rem 1rem 1.5rem;
    height: auto;
    display: flex;
    justify-content: space-between;
}

.RCM_clear_all {
    color: #acefff;
    font-size: 0.75rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
}

.RCM_headre_select_box.RCM_select_box .dropdown-menu {
    width: 19.19rem;
    padding: 0;
    /*max-height: 49.81rem;*/
}

    .RCM_headre_select_box.RCM_select_box .dropdown-menu.show {
        top: 3.75rem !important;
    }

    .RCM_headre_select_box.RCM_select_box .dropdown-menu .RCM_selectBox_scrollArea {
        max-height: calc(100vh - 30em);
        overflow-x: auto;
        box-shadow: 0 0.5rem 0.5rem 0 rgba(0,0,0,0.24);
    }


.RCM_select_box .RCM_select_checkbox {
    margin-right: 1rem;
    margin-right: 1.25rem;
}

input[type="checkbox" i].RCM_select_checkbox {
    margin-right: 1.25rem;
    margin-top: 0.1875rem;
}

.RCM_select_li {
    color: #ececec;
    font-size: 1.063rem;
    border-bottom: 0.5px solid var(--color-near-light-grey);
    padding-left: 1.25rem;
    background-color: #252f39;
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    overflow-wrap: break-word;
    white-space: normal;
    padding: 1.188rem 1.25rem;
}

    .RCM_select_li:hover, .RCM_li_selected {
        color: #ececec;
        background-color: #252f39;
    }

.RCM_li_selected {
    color: #ececec;
    background-color: #252f39;
}

li.RCM_select_li:last-child {
    border-bottom: 0.5px solid var(--color-near-light-grey);
}

/* .RCM_search_bar {
    width: 100%;
    color: var(--color-nero);
    font-size: 1.063rem;
    border: 0;
    border-bottom: 0.5px solid var(--color-whisper-shade-of-gray);
    line-height: 2.25rem;
    padding-left: 1.25rem;
    background-color: var(--color-white);
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 43%;
    background-image: url(./images/search.svg);
} */

.RCM_search_bar::placeholder {
    color: var(--color-nero);
    opacity: 1; /* Firefox */
}

.RCM_search_bar:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-nero);
}

.RCM_search_bar::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-nero);
}

.RCM_search_bar:focus {
    outline: none;
}

.dropdown-toggle::after {
    height: 0.5625rem;
    width: 0.9375rem;
    background-image: url(./images/arrow-down.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: transparent;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    float: right;
}

.RCM_Document_Dropdown.dropdown.RCM_select_box.RCM_mm_subselect_box.dropdown .btn-secondary {
    white-space: nowrap;
    overflow: hidden;
    width: 9.438rem;
    text-overflow: ellipsis;
}

.RCM_Document_Dropdown.dropdown .dropdown-toggle::after, .RCM_Activity_Dropdown.dropdown .dropdown-toggle::after {
    position: absolute;
    top: 0.4375rem;
    right: 0;
}

.RCM_select_box.RCM_mm_subselect_box.RCM_All_Bills_Dropdown.dropdown .dropdown-menu {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%
}

.RCM_Activity_Dropdown.dropdown.RCM_select_box.RCM_mm_subselect_box.dropdown .btn-secondary {
    white-space: nowrap;
    overflow: hidden;
    width: 9.438rem;
    text-overflow: ellipsis;
}

/* .RCM_select_box.RCM_mm_subselect_box .dropdown-toggle::after {
    background-image: url(./images/arrow_down_green.svg);
    margin-top: 0.25rem;
} */

.RCM_headre_select_box.RCM_select_box .RCM_select_li {
    display: flex;
    padding: 0.75rem 1.25rem 0.25rem 3rem;
}

.RCM_headre_select_box.RCM_select_box .RCM_all_Accounts .RCM_select_li:first-child {
    padding: 0.75rem 1rem 0.25rem 1rem;
}

.RCM_headre_select_box.RCM_select_box .dropdown-toggle::after {
    float: none;
    margin-left: 0.5rem;
}

.RCM_headre_select_box.RCM_select_box.dropdown.show .dropdown-toggle::after {
    margin-top: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    vertical-align: inherit;
}

.RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-item:first-child {
    border-top: none;
}

.RCM_select_box.dropdown.show .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.navbar-brand {
    width: 19.19rem;
    /*height: 3.75rem;*/
}

.RCM_headre_select_box.RCM_select_box .dropdown-toggle {
    width: 19.19rem;
    height: 3.75rem;
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.31);
    background-color: #252f39;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
    border: none;
}

.RCM_select_btn_container {
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.5);
    background-color: #2a3641;
    padding: 1.813rem 0.875rem;
    text-align: center;
}

    .RCM_select_btn_container .RCM_button_secondary, .RCM_select_btn_container .RCM_button_secondary:hover, .RCM_select_btn_container .RCM_button_secondary:disabled,
    .RCM_select_btn_container .RCM_button_secondary:visited, .RCM_select_btn_container .RCM_button_secondary:active:focus,
    .RCM_select_btn_container .RCM_button_primary, .RCM_select_btn_container .RCM_button_primary:hover, .RCM_select_btn_container .RCM_button_primary:disabled,
    .RCM_select_btn_container .RCM_button_primary:visited, .RCM_select_btn_container .RCM_button_primary:active:focus {
        width: 95%;
    }

/* .RCM_accounts_img {
    width: 1.375rem;
    height: 1.5rem;
    background-color: var(--color-white);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    margin-right: 0.6rem;
    background-image: url('./images/Accounts.svg');
}

.RCM_headre_select_box.RCM_select_box .dropdown-toggle:hover .RCM_accounts_img {
    background-image: url('./images/Accounts_hover.svg');
}

.RCM_headre_select_box.RCM_select_box.dropdown.show .RCM_accounts_img {
    background-image: url('./images/Accounts_selected.svg');
} */

.RCM_applyBtn_container {
    margin-bottom: 0.625rem;
}

.RCM_button_primary.RCM_accordian_btn, .RCM_button_primary.RCM_accordian_btn:hover, .RCM_button_primary.RCM_accordian_btn:disabled,
.RCM_accordian_btn.btn-primary:not(:disabled):not(.disabled):active, .RCM_accordian_btn.btn-primary:not(:disabled):not(.disabled):active:focus,
.RCM_accordian_btn.RCM_button_primary:focus {
    background-color: #2a3641;
    box-shadow: 0 0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    width: 100%;
    border-radius: 0;
    text-align: left;
    padding: 1rem 1rem 0.5rem 1rem;
    height: auto;
    display: flex;
}

.RCM_button_primary.RCM_accordian_btn_noAccount, .RCM_button_primary.RCM_accordian_btn_noAccount:hover, .RCM_button_primary.RCM_accordian_btn_noAccount:disabled,
.RCM_accordian_btn_noAccount.btn-primary:not(:disabled):not(.disabled):active, .RCM_accordian_btn_noAccount.btn-primary:not(:disabled):not(.disabled):active:focus,
.RCM_accordian_btn_noAccount.RCM_button_primary:focus {
    padding: 1rem;
}

.RCM_Bank_Name {
    width: 14.19rem;
    white-space: normal;
    padding-top: 0.125rem;
}

/* .RCM_arrow_right {
    height: 0.8125rem;
    width: 0.9375rem;
    background-image: url('./images/arrow-right.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: transparent;
    display: inline-block;
    margin-top: 0.375rem;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    float: right;
} */

.RCM_arrow_down {
    height: 0.8125rem;
    width: 0.9375rem;
    background-image: url(./images/arrow-right.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: transparent;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    float: right;
    margin-top: 0.375rem;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.RCM_select_no_account_msg {
    color: #ececec;
    font-size: 1.063rem;
    border-bottom: 0.5px solid var(--color-near-light-grey);
    padding-left: 1.25rem;
    background-color: #252f39;
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    overflow-wrap: break-word;
    white-space: normal;
    padding: 0.8rem 1.25rem 0.8rem 3rem;
}

.RCM_no_account_label {
    width: 2rem;
}
/*Header all account dropdown end*/
/*button start*/
.RCM_button_primary, .RCM_button_primary:hover, .RCM_button_primary:disabled {
    color: #fff;
    width: 17.5rem;
    height: 2.5rem;
    border-radius: 1.375rem;
    background-color: #16a5b0;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: center;
    border: none;
    font-family: 'Avenir-DemiBold';
}

    .RCM_button_primary:disabled {
        cursor: not-allowed;
    }

.RCM_button_secondary:focus {
    box-shadow: none;
}

.RCM_button_primary:focus {
    box-shadow: none;
    background-color: #16a5b0;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus {
    background-color: #16a5b0;
    border: none;
    color: #fff;
    box-shadow: none;
}

.RCM_button_secondary, .RCM_button_secondary:hover, .RCM_button_secondary:disabled,
.RCM_button_secondary:visited, .RCM_button_secondary:active:focus {
    width: 15.5rem;
    height: 2.5rem;
    border-radius: 1.375rem;
    background-color: #191f28;
    border: none;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: center;
    color: #aab0b6;
    border: solid 0.0625rem #979797 !important;
    font-family: 'Avenir-DemiBold';
}

    .RCM_button_secondary:disabled {
        cursor: not-allowed;
    }


/*.RCM_button_secondary:hover {
        border: 1.5px solid var(--color-dark-blue);
        background-color: var( --color-solitude-blue);
        color: var(--color-dark-blue);
    }

    .RCM_button_secondary:disabled {
        border: 1.5px solid var(--color-grey-chateau);
        background-color: var( --color-solitude-gray);
        color: var(--color-lighter-dark-gray);
    }*/
.RCM_overlapping_btn {
    margin: 0 1rem 1rem 0;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):active:focus {
    border: none;
    background-color: transparent;
    color: #aab0b6;
    box-shadow: none;
}

.RCM_button_add {
    color: #ffffff;
    font-size: 1.25rem;
    cursor: pointer;
}

.RCM_button_delete {
    color: #ffffff;
    font-size: 2rem;
    padding-left: 1.1rem;
    cursor: pointer;
}
/*button end*/

/*Tiles start*/
.RCM_Toast {
    background-color: #191f28;
    margin-top: 0.2rem;
    height: 100%;
    min-height: 100%;
    padding: 0.2rem 2.188rem;
}

.no_padding {
    padding: 0.5rem
}

.RCM_tile_heading {
    font-family: Avenir;
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    color: #fff;
}
.RCM_Toast_heading.RCM_transaction_heading {
    font-size: 12px;
}
.RCM_summary_section {
    margin-bottom: 1.875rem;
    min-height: 32.44rem;
    height: 32.44rem;
}

.RCM_detailsLabel {
    margin-left: 0.25rem;
}

@media (max-width: 1280px) {
    .RCM_summary_section {
        margin-bottom: 1.875rem;
        min-height: 38.44rem;
        height: 38.44rem;
    }
}
/*Tiles end*/
.RCM_atw {
    color: #acefff;
}
/*Topten summary section start*/
.RCM_holdingdiv {
    padding-top: 0.5625rem;
}

ul {
    list-style-type: none;
    padding: 0rem;
}

.RCM_active {
    background-color: #0E98CB;
}

.RCM_li_topten {
    border-bottom: 0.0625rem solid #dee2e6;
}

li.RCM_li_topten:last-child {
    border-bottom: 0rem;
}

.RCM_li_topten {
    border-bottom: solid 0.0625rem #4e545a;
}

.RCM_holdingWrapper {
    padding-left: 1rem;
}

.RCM_holdinfsection {
    min-height: 24.77rem;
    max-height: 24.77rem;
    padding-left: 0;
}

.RCM_holdingvalue {
    white-space: nowrap;
    width: 100%;
    font-family: "Avenir";
    font-size: 0.85rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0625rem;
    color: #fff;
    line-height: 3rem;
}

.RCM_holdingname {
    font-family: "Avenir";
    font-size: 0.8125rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #fff;
}

.RCM_value_arrow_wrapper {
    margin-top: -0.625rem;
    font-size: 1.375rem;
    color: var(--color-near-black);
    line-height: 1.688rem;
    display: flex;
}

/* .arrow-up {
    background-image: url(./images/upArrow_topten.svg);
    background-repeat: no-repeat;
    height: 0.875rem;
    width: 0.75rem;
    background-size: auto 100%;
    background-position: left top;
    margin-top: 1rem;
    margin-right: 0.5rem;
    float: right;
}

.arrow-down {
    background-image: url(./images/downArrow_topten.svg);
    background-repeat: no-repeat;
    height: 0.875rem;
    width: 0.75rem;
    background-size: auto 100%;
    background-position: left top;
    margin-top: 1rem;
    margin-right: 0.5rem;
    float: right;
}

/*Media query for IE 11 start*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .arrow-up {
        background-image: url(./images/upArrow_topten.svg);
        background-repeat: no-repeat;
        height: auto;
        width: 0.75rem;
        background-size: auto 100%;
        background-position: left top;
        margin-top: -0.5rem;
        margin-right: 0;
        float: right;
    }

    .arrow-down {
        background-image: url(./images/downArrow_topten.svg);
        background-repeat: no-repeat;
        height: 0.95rem;
        width: 1rem;
        background-size: auto 100%;
        background-position: left top;
        margin-top: 0.25rem;
        margin-right: 0;
        float: right;
    }
}

*/
/*Media query for IE 11 end*/
.RCM_text_right {
    text-align: right;
    margin-bottom: 0.75rem;
}

.RCM_div_space {
    padding-bottom: 2.875rem;
}

.RCM_tth_left_toggle:focus {
    box-shadow: none;
}

.RCM_topten_sub_heading {
    height: 0.9375rem;
    font-family: Avenir;
    font-size: 0.6875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #fff;
    margin-bottom: 1.625rem;
}
/*Topten summary section end*/
/*Asset summary style start*/
.RCM_asset_li {
    display: flex;
    padding: 1rem 0;
    cursor: pointer;
}

.RCM_asset_accountCol {
    padding-left: 0.4rem
}

.RCM_asset_li .row {
    width: 100%;
}

.RCM_asset_lidivright {
    flex: 1;
    text-align: right;
}

.RCM_asset_innerli {
    display: grid;
}

.RCM_asset_li_header {
    background: #2A3641 !important;
}

li.RCM_asset_li:nth-child(odd), li.RCM_Liability_li:nth-child(odd) {
    background: #191f28;
}

li.RCM_asset_li:nth-child(even), li.RCM_Liability_li:nth-child(even) {
    background: #353c44;
}

.RCM_assetwrapper {
    height: 21rem;
    max-height: 21rem;
    overflow: auto;
}


.RCM_asset_ul {
    /*margin-right: 1rem;*/
}

.RCM_assetacc {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
    word-break: break-all;
    padding-left: 0.5rem;
}

.RCM_asset_label {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
}

.RCM_assetval {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
    word-break: break-all;
}

.RCM_positiveval {
    color: #00A647;
}

.RCM_negativeval {
    color: #FB0000;
}

.RCM_assetspantot {
    display: flex;
    word-break: break-all;
    justify-content: flex-end;
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    border: none;
    word-break: break-all;
}

.RCM_removepadding_right {
    padding-right: 0;
}
/*Asset summary style end*/
/* Styles for transfers */
.RCM_sub_container {
    background-color: #0e1319;
    padding-bottom: 2rem;
}

.RCM_Toast_heading {
    color: #ffffff;
    letter-spacing: 0.1667rem;
    font-size: 0.75rem;
}

.RCM_Toast_sub_heading {
    color: #ffffff;
    letter-spacing: 0.1467rem;
    font-size: 0.6875rem;
}

.RCM_Toast_padded {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
}

.RCM_Toast_padded_top {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    padding-top: 2.5rem;
}

.RCM_CM_form {
    /*margin-top: 2.5rem;
    margin-left: 6.0rem;
    margin-right: 6.0rem;
    */
    margin-top: 0;
}

    .RCM_CM_form .row {
        margin-top: 1rem;
    }

.RCM_CM_form_label {
    font-family: "Avenir-Regular";
    color: #ececec;
    letter-spacing: 0.1367rem;
    font-size: 1rem;
}

    .RCM_CM_form_label label {
        font-family: "Avenir-Regular";
        color: #ececec;
        letter-spacing: 0.1367rem;
        font-size: 0.75rem;
    }

.RCM_form_control, .RCM_type_a_head_wrapper .rbt-input-main.form-control {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    width: 100%;
    border: 0;
    color: #ececec;
    font-size: 1rem;
    width: 100%;
    height: 2.5rem;
}
    .RCM_type_a_head_wrapper .rbt-input-main.form-control:focus{
        border:0;
        box-shadow:none
    }
    input.RCM_form_control {
        padding-left: 0.75rem;
    }

label.RCM_form_control, textarea.RCM_form_control {
    padding-top: 0.60rem;
    padding-left: 0.75rem;
    margin-bottom: 0;
}
.RCM_type_a_head_wrapper .rbt-menu.dropdown-menu.show {
    background-color: #2a3641;
    color: #ececec;
}
    .RCM_type_a_head_wrapper .rbt-menu.dropdown-menu.show mark, .RCM_type_a_head_wrapper .rbt-menu.dropdown-menu.show .dropdown-item:hover {
        background-color: #16a5b0;
    }
    .RCM_type_a_head_wrapper .rbt-menu.dropdown-menu.show .dropdown-item {
        color: #ececec;
    }
.RCM_CM_form_row {
    margin-top: 0.675rem;
}

.RCM_select_box.RCM_mm_select_box.dropdown .btn-secondary {
    margin-left: 0;
    width: 100%;
    border: 0;
    border-radius: 0;
    background-color: #2a3641;
    color: #ececec;
    text-align: left;
    height: 2.5rem;
    font-size: 0.875rem;
}

    .RCM_select_box.RCM_mm_select_box.dropdown .btn-secondary:focus {
        box-shadow: 0 0 0 0 !important;
    }

.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item {
    font-family: "Avenir-Regular";
    border-top: 0.0625rem solid #4e545a;
    border-radius: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 0.875rem;
    white-space: pre-wrap;
    min-height:2.6rem;
}

    .RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item:focus,
    .RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item:hover,
    .RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item:active {
        font-family: "Avenir-Regular";
        border-top: 0.0625rem solid #4e545a !important;
        background-color: #2a3641 !important;
        border-radius: 0;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.5);
        white-space: pre-wrap;
    }

    .RCM_select_box.RCM_mm_select_box.dropdown .dropdown-item > div {
        font-family: "Avenir-Regular";
        color: #fff;
    }

.RCM_select_box.RCM_mm_select_box.dropdown .dropdown-menu {
    margin-top: 0;
    background-color: #2a3641;
    border-radius: 0;
    width: 100%;
}
.RCM_dropdown_partyID .RCM_select_box.RCM_mm_select_box.dropdown .btn-secondary {
    width: auto;
    min-width: 15rem;
}
.RCM_CM_new_transfer {
    background-color: rgba(125,255,140, 0.4) !important;
}

.RCM_CM_withhold_toast {
    background-color: #2a3641;
    padding: 1rem;
}

.RCM_CM_Withhold_radio_text {
    width: unset;
    height: unset;
    background-color: #6c757d;
}

.RCM_CM_withhold_toast label span {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Avenir-Regular";
    letter-spacing: normal;
}

.RCM_CM_withhold_toast label {
    display: unset;
}


.RCM_select_box.RCM_mm_subselect_box.dropdown .btn-secondary {
    background-color: #191f28;
    border: 0;
    border-radius: 0;
    font-size: 0.75rem;
    letter-spacing: 0.125rem;
    color: #16a5b0;
    text-transform: uppercase;
    width: 100%;
}

.RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-menu {
    width: 100%;
}

.RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-item {
    font-family: "Avenir-Regular";
    border-top: 0.0625rem solid #4e545a;
    border-radius: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 0.75rem;
    white-space: pre-wrap
}

    .RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-item:focus,
    .RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-item:hover,
    .RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-item:active {
        font-family: "Avenir-Regular";
        border-top: 0.0625rem solid #4e545a !important;
        background-color: #2a3641 !important;
        border-radius: 0;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.5);
        white-space: pre-wrap
    }

    .RCM_select_box.RCM_mm_subselect_box.dropdown .dropdown-item > div {
        font-family: "Avenir-Regular";
    }

.RCM_CM_delete-icon {
    background-image: url(./images/delete.svg);
    background-repeat: no-repeat;
    height: 1.433rem;
    width: 1.533rem;
    background-size: auto 100%;
    background-position: left top;
    cursor: pointer;
}

.RCM_CM_more-icon {
    background-image: url(./images/more.svg);
    background-repeat: no-repeat;
    height: 1.433rem;
    width: 1.533rem;
    background-size: auto 100%;
    background-position: left top;
    cursor: pointer;
}

.RCM_CM_form_warning {
    font-size: 0.6875rem;
}

.RCM_transfers_datepicker .react-datepicker-wrapper,
.RCM_transfers_datepicker .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
}
/* End of styles for transfers */
/* Start two level table */
.RCM_two_level_table1 {
    color: #ffffff;
}

    .RCM_two_level_table1 thead tr, .RCM_two_level_table1 tfoot tr {
        background-color: #2a3641;
    }

        .RCM_two_level_table1 thead tr th {
            font-size: 1rem;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            border: none;
            vertical-align: middle;
            padding: 0.85rem 0.5rem;
        }

    .RCM_two_level_table1 tbody tr {
        height: 2.188rem;
        border-bottom: 0.0625rem solid #242a32;
    }

        .RCM_two_level_table1 tbody tr td {
            font-size: 1rem;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 0.5px;
            color: #fff;
            border: none;
            vertical-align: middle;
            padding: 0.5rem;
        }

        .RCM_two_level_table1 tbody tr:nth-child(odd) {
            background: rgba(125,133,140,0.05);
        }
        /*  Define the background color for all the EVEN background rows  */
        .RCM_two_level_table1 tbody tr:nth-child(even) {
            background: #191f28;
        }

    .RCM_two_level_table1 tfoot tr th {
        font-size: 0.75rem;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        border: none;
        vertical-align: middle;
        padding: 0.85rem 0.5rem;
    }
/* End Two level table */


/* Start Tabs Sub Routing */

.RCM_NavTab_container {
    border: 0;
    padding-top: 1.7rem;
    padding-bottom: 0.6rem;
    display: table;
    margin: 0 auto;
    text-align: center;
    place-items: center;
}


    .RCM_NavTab_container .nav-tabs {
        border: 0;
    }

    .RCM_NavTab_container .nav-item {
        border: 0;
        margin-right: 2rem;
        margin-bottom: 0;
    }



    .RCM_NavTab_container .nav-link {
        font-family: "Avenir-DemiBold";
        color: #ececec;
        font-size: 0.75rem;
        letter-spacing: 0.1667rem;
        border: 0;
    }

        .RCM_NavTab_container .nav-link:hover {
            font-family: "Avenir-DemiBold";
            border: 0;
            font-size: 0.75rem;
            letter-spacing: 0.1667rem;
            text-decoration: none;
            color: #16a5b0;
        }

        .RCM_NavTab_container .nav-link.linkactive {
            font-family: "Avenir-DemiBold";
            border: 0;
            background-color: #0e1319;
            border-bottom: 0.125rem solid #16a5b0;
            color: #16a5b0;
        }

/* End of Tabs  */

.RCM_divider_horizontal {
    border: 0.0625rem solid #4e545a;
}

.RCM_CM_linkText_small {
    font-family: "Avenir-Regular";
    color: #ececec;
    font-size: 0.75rem;
    text-decoration: none;
}

    .RCM_CM_linkText_small.btn {
        border: none;
    }

    .RCM_CM_linkText_small:hover,
    .RCM_CM_linkText_small:focus,
    .RCM_CM_linkText_small:active {
        font-family: "Avenir-Regular";
        color: #ececec;
        font-size: 0.75rem;
        text-decoration: none;
    }

.fixed-area-overflow {
    max-height: 25rem;
    overflow-y: auto;
}

.RCM_Info_text {
    color: #ececec;
    font-size: 0.75rem;
}

/* Start Overlay CSS */


.RCM_CM_Overlay {
    z-index: 99;
    background-color: rgba(25,31,40, 0.4);
    position: fixed;
    width: 99%;
    height: 100%;
    padding: 0;
    top: 0;
    text-align: center;
}

.RCM_CM_Overlay_body {
    position: absolute;
    top: 25%;
    width: 100%;
}

.RCM_CM_Overlay_spinner {
    -webkit-appearance: none;
    background-image: url(./images/spinner.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 8rem;
    width: 8rem;
}

.RCM_Div_Overlay {
    position: relative;
    z-index: 9;
}

/* End Overlay Css */

/* Start Modal Popup */

.modal-content {
    background-color: #191f28;
}

.RCM_CM_modal_content {
    background-color: #191f28;
    font-size: 1rem;
}

    .RCM_CM_modal_content:hover,
    .RCM_CM_modal_content:active,
    .RCM_CM_modal_content:focus {
        border: 0.0625rem solid rgba(0,0,0,.2);
    }



    .RCM_CM_modal_content .modal-header {
        border: none;
    }

    .RCM_CM_modal_content .modal-body {
        border: none;
    }

    .RCM_CM_modal_content .modal-footer {
        border: none;
    }

    .RCM_CM_modal_content .close {
        font-size: 1.063rem;
        font-weight: normal;
        color: #ffffff;
        opacity: 1;
    }

.RCM_CM_modal_data {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-left: 4.5rem;
    margin-right: 4.5rem;
}

.RCM_CM_modal_class.modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-backdrop {
    background-color: rgba(0,0,0,0.5);
}

.modal-extralg {
    max-width: calc(100vw - 6rem);
    max-height: calc(100vh - 3rem);
}

/* End Modal Popup*/

/*Scroll bar start*/
.RCM_CM_scroll::-webkit-scrollbar {
    width: 0.1rem;
}

/* Track */
.RCM_CM_scroll::-webkit-scrollbar-track {
    width: 0.1rem;
    background: transparent;
}

/* Handle */
.RCM_CM_scroll::-webkit-scrollbar-thumb {
    opacity: 0.3;
    border-radius: 3.5px;
    background-color: rgba(216,216,216,0.3);
}

    /* Handle on hover */
    .RCM_CM_scroll::-webkit-scrollbar-thumb:hover {
        background: rgba(216,216,216,0.3);
    }
/*Over-riding NW CSS start*/
* ::-webkit-scrollbar {
    height: 0.3rem;
}

* ::-webkit-scrollbar-thumb {
    opacity: 0.3;
    border-radius: 5.5px;
    background-color: rgba(216,216,216,0.5) !important;
}

modal::-webkit-scrollbar-thumb {
    opacity: 0.3;
    border-radius: 5.5px;
    background-color: #fff !important;
}

* ::-webkit-scrollbar-track {
    width: 0.1rem;
    background: transparent;
}

* ::-webkit-scrollbar-thumb:hover {
    background: rgba(216,216,216,0.3);
}
/*Over-riding NW CSS end*/

/*Scroll bar end*/
/*Financial Overview start*/
div.RCM_fo_name {
    width: 100%;
    text-align: left;
    border-bottom: 0.0625rem dashed #7d858c;
    line-height: 0.5em;
    margin: 0.3125rem 0 1rem;
    font-family: "Avenir";
    font-size: 0.839rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.6px;
    color: #fff;
}


    div.RCM_fo_name span {
        background-color: #0e1319;
        padding: 0 0.3125rem;
    }

.RCM_fo_value {
    font-size: 0.875rem;
    color: #fff;
    text-align: right;
    padding-left: 0.625rem;
}

div.RCM_tabledata .RCM_rowdata:nth-child(3), div.RCM_tabledata .RCM_rowdata:nth-child(4) {
    margin-left: 1rem;
}

/*div.RCM_tabledata .RCM_rowdata:nth-child(3) .RCM_fo_name, div.RCM_tabledata .RCM_rowdata:nth-child(4) .RCM_fo_name {
        margin: 7px 0 0.875rem;
    }*/

div .RCM_rowdata {
    display: flex;
}

div.RCM_tabledata.slide2 .RCM_rowdata:nth-child(3), div.RCM_tabledata.slide2 .RCM_rowdata:nth-child(4) {
    margin-left: 0rem;
}

#RCM_activitydetailstable .react-bootstrap-table, .RCM_SecurityHolding_Wrapper, .RCM_Tables, #RCM_manageExtAccountData .react-bootstrap-table,
.RCM_Tax_doc_table_wrapper, #RCM_Collabrationlist .react-bootstrap-table {
    overflow-x: auto;
}
/*Financial Overview end*/
/*tooltip classes*/
.RCM_toolTip {
    border-radius: 0.1875rem;
    color: #0e1319;
    background-color: #fff;
    font-size: 0.8125rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}

.RCM_toolTip_arrow {
    visibility: hidden;
}

.RCM_toolTip:before {
    border: solid;
    border-color: #fff transparent;
    border-width: 0.65rem 0.65rem 0 0.65rem;
    bottom: 37%;
    content: "";
    right: -0.4375rem;
    position: absolute;
    z-index: 99;
    transform: rotate(270deg);
    top: 0.375rem;
}
/* Errors and containers */

.RCM_validation_error_container {
    margin-top: 0.6rem;
    background-color: transparent;
    padding: 0.625rem;
}

.RCM_validation_error_text {
    color: #B71C1C;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.167rem;
    display: flex;
}

    .RCM_validation_error_text .RCM_error_img {
        height: 1.538rem;
        width: 1.538rem;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('./images/error.svg');
        margin-right: 0.3rem;
    }

.RCM_msg_text {
    color: #f85e5e;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 0.6875rem;
    letter-spacing: 0.167rem;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
}

.RCM_errorBoundryText {
    padding-left: 2rem;
    font-size: 0.75rem;
}
/* End error and containers*/
/*Start holding details modal popup*/
.RCM_holding_details_modal_content .modal-content {
    width: auto;
    background-color: #191f28;
}

    .RCM_holding_details_modal_content .modal-content:hover,
    .RCM_holding_details_modal_content .modal-content:active,
    .RCM_holding_details_modal_content .modal-content:focus {
        border: 0.0625rem solid rgba(0,0,0,.2);
    }

.RCM_holding_details_modal {
    width: 75rem;
}

.modal-dialog.RCM_holding_details_modal_content.modal-dialog-centered {
    right: 24.5%;
}

.RCM_holding_details_modal_content .modal-content .modal-body {
    padding-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: .5rem;
}

.RCM_holding_details_modal_content .modal-content .modal-footer {
    border-top: none;
    padding: 0rem;
}

.RCM_holding_details_modal_content .modal-content .modal-header .modal-title {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}

.RCM_holding_details_modal_content .modal-content .modal-header {
    border: none;
}

.RCM_right.RCM_Padding_Bottom {
    padding-bottom: 1rem;
}

.RCM_holding_dot {
    height: 0.25rem;
    width: 0.25rem;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0.0625rem;
}
/*End holding details modal popup*/
/*Start Common for all details pages*/
.RCM_Sort_arrow_icon {
    background-image: url(./images/sort-arrows.svg);
    background-repeat: no-repeat;
    height: 1.0rem;
    width: 1.0rem;
    background-size: auto 100%;
    background-position: left top;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.RCM_Sort_arrow_down_icon {
    background-image: url(./images/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 0.65rem;
    background-position: left center;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.RCM_Sort_arrow_up_icon {
    height: 1rem;
    width: 1rem;
    background-image: url(./images/arrow-up.svg);
    background-repeat: no-repeat;
    background-size: 0.65rem;
    background-position: left center;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.RCM_accsettingWrapper mgt-person {
    --avatar-size-s: 3.063rem;
}

.RCM_StickyNavBar_PrintExportWrapper {
    font-family: "Avenir";
    font-size: 0.5rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0625rem;
    color: #ececec;
    display: inline-block;
    padding-bottom: 0.125rem;
}

.RCM_printexportWrapper {
    font-family: "Avenir";
    font-size: 0.5rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0625rem;
    color: #ececec;
    border-bottom: 0.0625rem solid #ececec;
    display: inline-block;
    padding-bottom: 0.125rem;
}

.RCM_printexportWrapper_disabled {
    font-family: "Avenir";
    font-size: 0.5rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0625rem;
    color: #B1B1B1;
    border-bottom: 0.0625rem solid #B1B1B1;
    display: inline-block;
    padding-bottom: 0.125rem;
    cursor: auto;
}

.RCM_icon_hand:hover {
    cursor: pointer
}

.RCM_text_textdropdown {
    padding: 0.4rem 0 0 0;
}

.RCM_tiles_hr {
    margin-bottom: 0.4375rem;
}

.RCM_userMenu_Print {
    background-image: url('./images/print.svg');
}

    .RCM_menu_btn:hover .RCM_userMenu_Print, .RCM_userMenu_Print:hover {
        background-image: url('./images/print_selected.svg');
    }

.RCM_Download {
    background-image: url('./images/download.svg');
}

    .RCM_menu_btn:hover .RCM_Download, .RCM_Download:hover {
        background-image: url('./images/download_selected.svg');
    }

.RCM_userMenu_Refresh {
    background-image: url('./images/refresh.svg');
}

.RCM_userMenu_Refresh {
    margin-top: 0.55rem;
}

    .RCM_menu_btn:hover .RCM_userMenu_Refresh, .RCM_userMenu_Refresh:hover {
        background-image: url('./images/refresh_selected.svg');
    }

.table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: pointer;
}

    .table.RCM_two_level_table2.RCM_Headerless_table tbody tr:nth-child(odd) {
        background: rgba(125,133,140,0.34);
    }
    /*  Define the background color for all the EVEN background rows  */
    .table.RCM_two_level_table2.RCM_Headerless_table tbody tr:nth-child(even) {
        background: rgba(125,133,140,0.2);
    }

.table.RCM_two_level_table2.RCM_Headerless_table {
    margin: 0rem;
    width: 100%;
}

    .table.RCM_two_level_table2.RCM_Headerless_table thead {
        display: none;
    }

.RCM_detailsScreeen {
    margin-left: 0.9375rem;
}

.RCM_right {
    text-align: right;
}

.RCM_details_heading {
    padding-top: 0.2rem;
    padding-right: 2.188rem;
    cursor: auto;
}

.RCM_question_icon {
    background: url('./images/question_mark.svg') no-repeat;
    height: 1rem;
    width: 1rem;
    padding: 0rem;
    border: 0rem;
    background-size: auto 100%;
}

.RCM_Back_btn_container {
    text-align: right;
}

.RCM_activityDetailspage .table.RCM_two_level_table1 tbody tr {
    cursor: auto;
}

.RCM_detailsexpandview:focus {
    outline: 0;
}

.RCM_common_table_column_text {
    text-align: left;
}

.RCM_common_table_column_number {
    text-align: right;
}

.RCM_select_box.RCM_Account_Dropdown .dropdown-toggle.btn.btn-secondary {
    width: 17rem;
}

.RCM_select_box.RCM_Account_Dropdown .dropdown-item {
    white-space: pre-wrap;
    /*overflow: hidden;
    text-overflow: ellipsis;*/
}

.RCM_select_box.RCM_Account_Dropdown .dropdown-menu {
    width: 17rem;
}

.RCM_NavTab_container_details_no_padding {
    padding-top: 0rem;
}

/*End Common for all details pages*/
/*Start Holding details styles*/
.RCM_text_hd {
    padding: 1.5rem 0 1rem 0.9375rem;
}

.RCM_Holdingdetail_label {
    padding-top: 0.6rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.188rem;
    color: var(--color-dark-gray);
}

.RCM_Toptendetail_img {
    height: 1.563rem;
    width: 1.75rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 1.2rem;
    margin-bottom: 0.25rem;
}

.RCM_hdstablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: auto;
}

.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table1 thead tr th:first-child,
.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(7) {
    width: 10%;
    white-space: normal;
    word-break: break-all;
}

.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(3),
.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(4),
.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(5),
.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(6) {
    width: 7%;
    padding-right: 0;
    padding-left: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(2) {
    width: 20%;
    white-space: normal;
    word-break: break-all;
}

.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(8), .RCM_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(9) {
    width: 15%;
    white-space: normal;
    word-break: break-all;
}


.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:first-child,
.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(7) {
    width: 10%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(3),
.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(4),
.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(5),
.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(6) {
    width: 7%;
    white-space: normal;
    word-break: break-all;
}

.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(2) {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(8), .RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(9) {
    width: 15%;
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}


.RCM_Spanwidthfooter {
    width: 25%;
    text-align: left;
}

.RCM_Spanwidth {
    width: 30%;
    text-align: left;
}

.RCM_Spanwidthone {
    width: 70%;
    flex: auto;
}

.RCM_displayflex {
    display: flex;
}
.RCM_displayflex_justified_end {
    display: flex;
    justify-content: space-between;
}
/*End Holding details styles*/
/*start realized gain loss styles*/
.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table1 thead tr th:first-child {
    width: 14%;
    white-space: normal;
    word-break: break-all;
}

.RCM_Realized_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(3),
.RCM_Realized_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(4) {
    width: 12%;
    padding-right: 0;
    padding-left: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_Realized_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(2) {
    width: 20%;
    white-space: normal;
    word-break: break-all;
}

.RCM_Realized_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(5),
.RCM_Realized_SecurityHolding_Wrapper .RCM_two_level_table1 thead tr th:nth-child(6) {
    width: 20%;
    white-space: normal;
    word-break: break-all;
}


.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:first-child {
    width: 14%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(3),
.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(4) {
    width: 12%;
    white-space: normal;
    word-break: break-all;
}

.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(2) {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(5),
.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(6) {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}
/*end realized gain loss style*/
.RCM_subAccDropdown {
    width: 25%;
}

    .RCM_subAccDropdown .RCM_select_box.RCM_mm_subselect_box.dropdown.dropdown button {
        text-align: left;
    }

    .RCM_subAccDropdown .dropdown-header {
        white-space: normal;
    }
/* Documents */
.RCM_Document_Date_Inline_Div {
    display: inline-block;
    margin-left: -1rem
}

.RCM_select_box.RCM_mm_subselect_box.dropdown.RCM_Activity_Dropdown.dropdown.show .dropdown-header {
    white-space: normal;
}

.RCM_Inline_Div {
    display: inline-block;
}

.RCM_Document_Fields {
    margin-left: 1rem;
    /*min-height: 5rem;*/
}

.RCM_two_level_table1.RCM_Documents_Table tbody tr td:nth-child(5) {
    width: 30%
}

.RCM_two_level_table1.RCM_Correspondence_Documents_Table tbody tr td:nth-child(3) {
    width: 40%
}

.RCM_two_level_table1.RCM_Correspondence_Documents_Table tbody tr td:nth-child(3) {
    width: 40%
}


.RCM_two_level_table1.RCM_TradeConfirm_Table thead tr th:last-child {
    color: transparent;
    background-color: transparent;
}

.RCM_Document_Details_Row {
    height: 43rem
}

.RCM_two_level_table1.RCM_TradeConfirm_Table {
    margin-left: 0rem;
    overflow-x: auto;
    overflow-y: auto;
}

.RCM_Document_Spinner_Margin {
    margin-top: -1.35rem
}

.RCM_Document_Spinner_Date_Margin {
    margin-top: -0.3rem;
}

.RCM_Documents_btn_img {
    height: 1rem;
    width: 1.813rem;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 0.25rem;
}

.RCM_Home_btn_img {
    height: 1.8rem;
    width: 1.813rem;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 1.25rem;
}

.RCM_Prospect_Home_PrintExportBtns {
    pointer-events: none;
}

.RCM_Home_PrintExport {
    padding-top: 1rem
}

.RCM_userMenu_Print {
    background-image: url('./images/print.svg');
}

.RCM_Download {
    background-image: url('./images/download.svg');
}

.RCM_Document_CSV_Download {
    background-image: url(./images/excel.svg);
}

.RCM_Document_Message_Margin {
    margin-bottom: 1rem
}

.RCM_TradeConfirm_DateRange_Row {
    padding: 0rem;
    margin-left: -4rem
}

.RCM_AccountStatement_DateRange_Margin {
    margin-left: 1rem
}

.RCM_CM_grid-icon {
    background-image: url(./images/calendar.svg);
    background-repeat: no-repeat;
    height: 0.5rem;
    width: 1.5rem;
    margin-right: 0.9rem;
    background-position-y: 0.6rem
}

.RCM_bottom-label {
    color: #999;
    font-size: 0.6875rem;
    font-weight: normal;
    margin-bottom: 0rem
}

.RCM_TradeConfirm_DateRange_Search_Btn_Margin {
    margin-top: -1.3rem;
    padding-left: 0rem
}

.RCM_Document_Search {
    background-image: url(./images/search.svg);
    height: 1.3rem;
    margin-top: -0.05rem;
}

.RCM_userMenu_btn_img {
    height: 1.851rem;
    width: 2rem;
    background-repeat: no-repeat;
    margin-bottom: -1.25rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 27%;
    margin-top: 2rem;
}

.RCM_Document_Search {
    background-image: url(./images/search.svg);
    height: 1.851rem;
    width: 2rem;
    background-repeat: no-repeat;
    margin-bottom: -1.25rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 1.5rem;
}

.RCM_TradeConfirm_Search {
    background-image: url(./images/search.svg);
    height: 1.851rem;
    width: 2rem;
    background-repeat: no-repeat;
    margin-bottom: -1.25rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 47%;
    margin-top: 1rem;
}

.RCM_TradeConfirm_DownloadPrint_Column_Margin {
    margin-left: 1rem
}

.RCM_common_table_column_text {
    text-align: left;
}

.RCM_common_table_column_number {
    text-align: right;
}

.RCM_Toast.RCM_PdfViewer_Toast {
    min-height: 46.88rem
}

.RCM_Toast.RCM_Document_Toast {
    margin-top: 0rem;
    /*min-height: 40rem*/
}

.RCM_PdfViewer_Border {
    border-style: solid;
    border-color: #B4BEFF;
    border-width: 0.3125rem;
}

.RCM_select_box.RCM_DateRange_Dropdown .dropdown-toggle.btn.btn-secondary {
    width: 13rem;
}

.RCM_select_box.RCM_DateRange_Dropdown .dropdown-item {
    white-space: pre-wrap;
    /*overflow: hidden;
    text-overflow: ellipsis;*/
}

.RCM_select_box.RCM_DateRange_Dropdown .dropdown-menu {
    width: 13rem;
}

.RCM_Documents_TradeConfirm_Search_Div {
    padding-left: 0.5rem;
}


.RCM_Accounts_lbl {
    width: 4.875rem;
    height: 0.9375rem;
    font-family: "Avenir";
    font-size: 0.6875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ececec;
}

.RCM_Date_lbl {
    width: 4.875rem;
    height: 0.9375rem;
    font-family: "Avenir";
    font-size: 0.6875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ececec;
    /*margin-left: -1.1rem;
    margin-right: -1.3rem*/
}

.RCM_FromDate_Margin {
    margin-right: 0.5rem
}

.RCM_Stmt_FromDate_Bottom_lbl {
    margin-left: 5.9rem
}

.RCM_Stmt_ToDate_Bottom_lbl {
    margin-left: 4.2rem
}

.RCM_stmt_btn_container {
    width: 3.5rem;
}

.RCM_Document_Bottom_lbl {
    margin-left: 5.2rem
}

.RCM_ToDate_Bottom_lbl {
    margin-left: 4rem
}

.RCM_Trade_Search_Div {
    margin-left: -3.5rem
}

.RCM_select_box.RCM_mm_subselect_box.RCM_Document_Dropdown.dropdown .dropdown-toggle.btn.btn-secondary {
    background-color: #0e1319
}


.RCM_Document_Datepicker {
    font-size: 0.9375rem;
    background-image: url(./images/calendar.svg);
    background-color: #0e1319;
    color: white;
    border: none;
    border-bottom: 0.0625rem solid;
    background-repeat: no-repeat;
    padding-left: 2rem;
    display: block;
    width: 100%;
}

.RCM_Document_FromDate_Margin {
    margin-left: 0.5rem
}

.RCM_AccountActivityDetail_DateRange_Search_Btn_Margin {
    margin-left: -1.5rem;
    margin-top: -1.3rem;
    padding-left: 0rem
}

.RCM_AccountActivityDetail_Date_Div {
}

.RCM_Document_Datepicker.RCM_AccountAcitivityDetail_Datepicker {
    background-color: #191f28;
}

.RCM_AccountActivityDetails_Message_Div {
    min-height: 4rem;
    margin-top: -2rem;
    margin-bottom: -3rem;
}

@media (min-width: 1700px) {
    .RCM_Stmt_ToDate_Div {
        padding-right: 1.25rem;
    }
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -0.5rem;
    position: absolute;
}

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        box-sizing: content-box;
        position: absolute;
        border: 0.5rem solid transparent;
        height: 0;
        width: 1px;
    }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
        .react-datepicker__month-read-view--down-arrow::before,
        .react-datepicker__month-year-read-view--down-arrow::before {
            content: "";
            z-index: -1;
            border-width: 0.5rem;
            left: -8px;
            border-bottom-color: #aeaeae;
        }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-top: none;
        border-bottom-color: #f0f0f0;
    }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
            top: -1px;
            border-bottom-color: #aeaeae;
        }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom: none;
        border-top-color: #fff;
    }

        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
        .react-datepicker__month-read-view--down-arrow::before,
        .react-datepicker__month-year-read-view--down-arrow::before {
            bottom: -1px;
            border-top-color: #aeaeae;
        }

.react-datepicker-wrapper {
    display: inline-block;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 9;
}

    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: 0.625rem;
    }

    .react-datepicker-popper[data-placement^="top"] {
        margin-bottom: 0.625rem;
    }

    .react-datepicker-popper[data-placement^="right"] {
        margin-left: 8px;
    }

        .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
            left: auto;
            right: 42px;
        }

    .react-datepicker-popper[data-placement^="left"] {
        margin-right: 8px;
    }

        .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
            left: 42px;
            right: auto;
        }

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0.625rem;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 0.625rem;
    width: 0.625rem;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 0.625rem;
    border-right-color: #ccc;
}

    .react-datepicker__navigation--previous:hover {
        border: 0.45rem solid transparent;
        border-right-color: #b3b3b3;
    }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    right: 0.625rem;
    border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border: 0.45rem solid transparent;
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

    .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3;
    }

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

    .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3;
    }

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

    .react-datepicker__month .react-datepicker__month-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 0.3125rem 0 0.625rem 0.9375rem;
    text-align: left;
}

    .react-datepicker__input-time-container .react-datepicker-time__caption {
        display: inline-block;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container {
        display: inline-block;
    }

        .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
            display: inline-block;
            margin-left: 0.625rem;
        }

            .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
                width: 85px;
            }

                .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
                .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
                    -moz-appearance: textfield;
                }

        .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 70px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 70px;
        overflow-x: hidden;
        margin: 0 auto;
        text-align: center;
    }

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
            list-style: none;
            margin: 0;
            height: calc(195px + (1.7rem / 2));
            overflow-y: scroll;
            padding-right: 0px;
            padding-left: 0px;
            width: 100%;
            box-sizing: content-box;
        }

            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
                height: 30px;
                padding: 5px 0.625rem;
            }

                .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
                    cursor: pointer;
                    background-color: #f0f0f0;
                }

            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
                background-color: #216ba5;
                color: white;
                font-weight: bold;
            }

                .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
                    background-color: #216ba5;
                }

            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
                color: #ccc;
            }

                .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
                    cursor: default;
                    background-color: transparent;
                }

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

    .react-datepicker__week-number.react-datepicker__week-number--clickable {
        cursor: pointer;
    }

        .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
            border-radius: 0.3rem;
            background-color: #f0f0f0;
        }

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

    .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
        background-color: #1d5d90;
    }

.react-datepicker__month--disabled {
    color: #ccc;
    pointer-events: none;
}

    .react-datepicker__month--disabled:hover {
        cursor: default;
        background-color: transparent;
    }

.react-datepicker__day,
.react-datepicker__month-text {
    cursor: pointer;
}

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover {
        border-radius: 0.3rem;
        background-color: #f0f0f0;
    }

.react-datepicker__day--today,
.react-datepicker__month-text--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover {
        background-color: #32be3f;
    }

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover {
        background-color: #1d5d90;
    }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover {
        background-color: #1d5d90;
    }

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range {
    background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc;
}

    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover {
        background-color: transparent;
    }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    background-color: #216ba5;
}

.react-datepicker__month-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

    .react-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover,
    .react-datepicker__month-year-read-view:hover {
        cursor: pointer;
    }

        .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
            border-top-color: #b3b3b3;
        }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 1.25rem;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}

    .react-datepicker__year-dropdown:hover,
    .react-datepicker__month-dropdown:hover,
    .react-datepicker__month-year-dropdown:hover {
        cursor: pointer;
    }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 1.25rem;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__month-option:first-of-type,
    .react-datepicker__month-year-option:first-of-type {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:last-of-type,
    .react-datepicker__month-option:last-of-type,
    .react-datepicker__month-year-option:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
        background-color: #ccc;
    }

        .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
        .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
        .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
            border-bottom-color: #b3b3b3;
        }

        .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
        .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
        .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
            border-top-color: #b3b3b3;
        }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
}

    .react-datepicker__close-icon::after {
        background-color: #216ba5;
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        content: "\00d7";
        cursor: pointer;
        font-size: 0.75rem;
        height: 16px;
        width: 16px;
        line-height: 1;
        margin: -8px auto 0;
        padding: 2px;
        position: absolute;
        right: 0px;
        text-align: center;
    }

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
        border: 0.45rem solid transparent;
        border-right-color: #b3b3b3;
    }

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

    .react-datepicker__portal .react-datepicker__navigation--next:hover {
        border: 0.45rem solid transparent;
        border-left-color: #b3b3b3;
    }

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.RCM_DatePicker {
    color: black
}

.RCM_ToDate_Lbl {
    margin-left: -0.8rem
}

/* end of document*/
/* Feedback*/
.RCM_helpsupport_label {
    font-family: "Avenir";
    font-weight: 500;
    letter-spacing: 0.6px;
    color: #ececec;
    border: 0;
    vertical-align: middle;
}

.RCM_helpsupport_sublabel {
    font-family: "Avenir";
    font-weight: 100;
    letter-spacing: 0.6px;
    color: #ececec;
    border: 0;
    vertical-align: middle;
    font-size: 0.75rem;
}

#RCM_Case_manager {
    padding: 2rem;
    /*height: 43.13rem;*/
}

textarea {
    resize: none;
}

.RCM_Feedback_Label {
    top: 1rem;
}

.RCM_Feedback_Container {
    margin-top: 0.5rem;
    padding-left: 1rem;
    border-radius: 0;
    width: 100%
}

.RCM_Feedback_Message_Margin {
    margin-bottom: 1rem;
    height: 2rem;
}

.RCM_Feedback_FirstName {
    margin-top: 2rem;
    margin-bottom: -1rem
}

.RCM_Feedback_FeedbackDetails {
    margin-top: 2rem
}

.RCM_Feedback_TextArea {
    height: 8rem;
}

.RCM_Feedback {
    margin-left: 30rem;
    margin-right: 30rem;
}

.RCM_Feedback_Submit {
    margin-top: 2rem;
    /*margin-bottom: -2rem;*/
    text-align: center;
}

.RCM_Feedback_Success {
    color: #155724;
}

.RCM_Feedback_Success_Body {
    border: 0;
}

.RCM_Message_Warning_Body {
    border: 0;
    background: transparent;
    height: 0.5rem
}

.RCM_Feedback_Info {
    color: #0c5460
}

.RCM_Message_Warning {
    color: #f4ba12
}

.RCM_Help_Contact_lbl {
    margin-left: 1rem
}

.RCM_Help_Email_lbl {
    margin-left: 2.9rem
}

.RCM_CaseMgr_Name_lbl {
    margin-left: 1.3rem
}

.RCM_CaseMgr_Email_lbl {
    margin-left: 1rem
}

.RCM_CaseManager_Div {
    margin-left: 25rem
}

.RCM_CaseManager_Container {
    margin-top: 0.5rem;
    border-radius: 0;
    width: 100%
}

input.form-control.RCM_fileUpload {
    background-color: #2a3641;
    color: #ececec;
    border: none;
    padding: 0;
}

    input.form-control.RCM_fileUpload :active, input.form-control.RCM_fileUpload :focus {
        border: none;
    }

input[type="file"]#file-upload {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 0.6875rem 0.75rem;
    cursor: pointer;
    width: 8rem;
    color: #fff;
    height: 2.5rem;
    border-radius: 1.375rem;
    background-color: #16a5b0;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: center;
    border: none;
    font-family: 'Avenir-DemiBold';
}

.RCM_form_uploadbtn {
    font-family: "Avenir-Regular";
    color: #ececec;
    letter-spacing: 0.1367rem;
    font-size: 0.75rem;
    margin-left: 2rem;
    cursor: auto;
}
/*CaseManager modal start*/
.RCM_casemanage_modalBody {
    width: 56rem;
    margin-left: 2rem;
}

.RCM_casemanage_modalcontent {
    width: 60rem;
}

.RCM_modal_toast.RCM_Toast {
    padding: 0;
    margin-top: 0;
}

.RCM_caseManager_modal_btn_container {
    text-align: right;
}

.RCM_caseManager_modal_cancel_btn {
    margin-left: 1rem;
}

.RCM_caseManager_modal_link {
    color: #16a5b0;
    cursor: pointer;
}

.RCM_caseManager_modal_link_container {
    color: #ffffff;
    letter-spacing: 0.1667rem;
    margin-top: 0.625rem;
    font-size: 0.75rem;
}

.RCM_add_asset_link {
    text-align: right;
    margin-top: 0.625rem;
}

    .RCM_add_asset_link a {
        color: #16a5b0;
        cursor: pointer;
        letter-spacing: 0.1667rem;
        font-size: 0.75rem;
    }

.RCM_link {
    margin: 0.625rem 0;
}

    .RCM_link label {
        color: #16a5b0;
        
        letter-spacing: 0.1667rem;
    }

.RCM_modal_toast #RCM_Case_manager {
    padding-top: 0;
}

.RCM_modal_toast .RCM_FeedbackDetails_modal {
    margin-top: 1rem;
}

.RCM_CM_modal_message_hidden {
    display: none;
}

@media (min-width: 992px) {
    .modal-dialog.RCM_casemanage_modal {
        max-width: 60rem;
        margin: 1.75rem auto;
    }
}

@media (max-width: 991px) {
    .modal-dialog.RCM_casemanage_modal {
        max-width: 45rem;
        margin: 1.75rem auto;
    }

    .RCM_casemanage_modalBody {
        width: 41rem;
        margin-left: 2rem;
    }

    .RCM_casemanage_modalcontent {
        width: 45rem;
    }
}
/*CaseManager modal start*/
/* end of Feedback*/
/*Summary section start*/
.RCM_headingwrapper {
    margin-bottom: 1.313rem;
}

.RCM_expandviewright {
    text-align: right;
    padding-top: 0.25rem;
}

.RCM_expandviewrightWrapper {
    display: flex;
    justify-content: flex-end;
}

.RCM_expandview {
    background: url(./images/expandView.svg) no-repeat;
    height: 1.75rem;
    width: 1.75rem;
    border: none;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    margin-bottom: 0.0625rem;
    margin-left: 0.5rem;
}

    .RCM_expandview:focus {
        outline: 0;
    }
/*toggle start*/
.RCM_togglelabeltext {
    font-size: 0.8rem;
    display: block;
    text-align: left;
    cursor: pointer;
}

/* .RCM_left_toggle {
    background: url('./images/list.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
} */

.RCM_Feedback_Success_Body {
    border: 0;
}


/* .RCM_left_toggle_active {
    background: url('./images/listSelected.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
} */
/*.RCM_left_toggle {
    background: url('./images/list.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
}*/
/* .RCM_right_toggle {
    background: url('./images/grid.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
    border-radius: .25rem 0 0 .25rem;
}

.RCM_right_toggle_active {
    background: url('./images/gridSelected.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
    border-radius: .25rem 0 0 .25rem;
}

.RCM_right_pie_toggle {
    background: url('./images/View_Pie_Chart.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
    border-radius: 0 .25rem .25rem 0;
}

.RCM_right_pie_toggle_active {
    background: url('./images/View_Pie_Chart_active.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border: 0rem;
    border-radius: 0 .25rem .25rem 0;
} */

.RCM_left_bar_toggle, .RCM_left_toggle {
    background: url('./images/bar_chart.svg') no-repeat;
    height: 1.5rem;
    width: 1.688rem;
    border: none;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1.313rem;
}

.RCM_left_bar_toggle {
    background: url('./images/Table.svg') no-repeat;
    height: 1.563rem;
    width: 1.5rem;
    border: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1.313rem;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}


.RCM_left_bar_toggle_active {
    background: url('./images/graph_view_selected.svg') no-repeat;
    height: 2rem;
    width: 2.4rem;
    padding: 0rem;
    border-radius: 0 .25rem .25rem 0;
    border: 0rem;
}

.RCM_left_toggle:focus, .RCM_left_toggle_active:focus, .RCM_right_toggle :focus, .RCM_right_toggle_active:focus {
    box-shadow: none;
    outline: 0;
}

.RCM_left_toggle:active, .RCM_left_toggle_active:active, .RCM_right_toggle :active, .RCM_right_toggle_active:active {
    box-shadow: none;
    outline: 0;
}


.RCM_Profile {
    margin-left: 15rem;
    margin-right: 15rem;
}

.RCM_preference_label {
    padding-left: 1rem;
}

.RCM_nickname_table {
    margin-top: 0.5rem;
}

.RCM_button_primary.RCM_prefsave_btn, .RCM_button_primary.RCM_prefsave_btn:hover, .RCM_button_primary.RCM_prefsave_btn:disabled {
    width: 15.5rem;
}
/*toggle end*/

/*Summary section end*/

/*spinner start*/
.RCM-Spinner {
    align-content: center;
    margin: 0.625rem;
}

.RCM_spinner {
    display: flex;
    font-size: 0.75rem;
    letter-spacing: 0.167rem;
    justify-content: center;
}

    .RCM_spinner .text-primary {
        color: #ffffff !important;
    }

.spinner-grow {
    width: 0.4rem;
    height: 0.4rem;
}
/*spinner end*/
/*Asset allocation details start*/
.RCM_aadtablewrapper .table.RCM_two_level_table1 thead tr th:first-child, .RCM_aadtablewrapper .RCM_two_level_table1 thead tr th:nth-child(2), .RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:first-child, .RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(2) {
    width: 15%;
}

.RCM_aadtablewrapper .RCM_two_level_table1 thead tr th:nth-child(3), .RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(3) {
    width: 20%
}

.RCM_aadtablewrapper .RCM_two_level_table1 thead tr th:nth-child(4), .RCM_aadtablewrapper .RCM_two_level_table1 thead tr th:nth-child(5), .RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(4), .RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(5) {
    width: 10%;
}

.RCM_aadtablewrapper .RCM_two_level_table1 thead tr th:nth-child(6) {
    width: 14.8%;
}

.RCM_aadtablewrapper .RCM_two_level_table1 thead tr th:nth-child(7) {
    width: 12%;
}

.RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(6) {
    width: 15%
}

.RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(7) {
    width: 15%
}

    .RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(7) span {
        margin-right: 21%;
    }

.RCM_profile_editmail {
    max-height: 33.33rem;
    overflow-y: scroll;
}

/*Asset allocation details end*/
/*    hand icon changes*/
.RCM_aadtablewrapper .RCM_two_level_table1 .RCM_two_level_table2 tbody tr,
.RCM_SecurityHolding_Wrapper .RCM_two_level_table1 .RCM_two_level_table2 tbody tr {
    cursor: auto;
}

.RCM_aadtablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: auto;
}

.RCM_balancetable .RCM_two_level_table1 tbody tr {
    cursor: auto;
}

.RCM_activityDetailspage .table.RCM_two_level_table1 tbody tr {
    cursor: auto;
}

.RCM_balanceDetails .table.RCM_two_level_table1 tbody tr {
    cursor: auto;
}

.RCM_CM_no_hand table tbody tr {
    cursor: auto;
}

.RCM_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: pointer;
}

.RCM_hdstablewrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: auto;
}

.RCM_holding_details_modal_content .modal-content tbody tr {
    cursor: auto;
}

.RCM_summary_section .table.RCM_two_level_table1 tbody tr {
    cursor: auto;
}

.RCM_activity_table .table tbody tr {
    cursor: auto;
}

.RCM_preference_theme {
    padding: 0;
    margin: 1rem 0 1.5rem 0;
}

#RCM_DocumentDeliveryDetails.RCM_Toast, #RCM_DocumentDeliverySettings.RCM_Toast {
    margin-top: 1.5rem;
}

.RCM_CM_Desclaimer {
    width: 138%
}

.RCM_Collabaration_folder {
    background-image: url('./images/folder.svg');
    height: 1.125rem;
    width: 1.125rem;
}

.RCM_Collabaration_unknown {
    background-image: url('./images/unknown.svg');
    height: 1.125rem;
    width: 1.125rem;
}

.RCM_Collabaration_txt {
    background-image: url('./images/txt.svg');
    height: 1.125rem;
    width: 1.125rem;
}

.RCM_Collabaration_pdf {
    background-image: url('./images/pdf.svg');
    height: 1.125rem;
    width: 1.125rem;
}

.RCM_Collabaration_word {
    background-image: url('./images/word.png');
    height: 1.125rem;
    width: 1.125rem;
}

.RCM_Collabaration_excel {
    background-image: url('./images/excel.svg');
    height: 1.125rem;
    width: 1.125rem;
}

.RCM_collaboration_btn, .RCM_collaboration_btn:hover, .RCM_collaboration_btn:disabled {
    width: 1.0rem;
    height: 1.5rem;
    border: none;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: left;
    color: #fff;
    font-family: 'Avenir-DemiBold';
}

.RCM_collaborationlaunch_btn, .RCM_collaborationlaunch_btn:hover, .RCM_collaborationlaunch_btn:disabled {
    width: 25.0rem;
    height: 2.5rem;
    border: none;
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    float: right;
}

.RCM_Collaboration_upload {
    width: 5rem;
}

.RCM_Collabaration_RadioBtn {
    position: relative !important;
    left: 0.5rem !important;
    top: 0.3rem !important;
}
/*hand icon end*/


/* reduced button size*/

.RCM_reduce_btn_size, .RCM_reduce_btn_size:hover, .RCM_reduce_btn_size:disabled, .RCM_reduce_btn_size:active {
    width: 10rem !important;
}

/*help and support*/
.RCM_Bot_Icon {
    margin-top: 0.4rem;
    margin-left: 0.5rem;
    text-align: center;
    width: 4rem;
    height: 2rem;
}

.RCM_Bot {
    background-size: auto 70%;
    background-position: center;
    background-color: #2a3641;
    position: fixed;
    bottom: 1.25rem;
    right: 1.25rem;
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
}

.RCM_Help_Bot_Popup {
    position: absolute;
    /*margin-left:100rem;
        margin-top:30rem;*/
}

.pesonalise-main-container {
    width: 39rem;
    height: 49.37rem;
    background: var(--color-tile-white);
    border-radius: 0.4375rem;
    -webkit-box-shadow: 0 0 1.875rem 0.125rem rgba(0,0,0,0.12), 0 0 1.25rem 0.125rem rgba(0,0,0,0.15);
    position: absolute;
    left: -33rem;
    box-shadow: 0 0 1.875rem 0.125rem rgba(0,0,0,0.12), 0 0 1.25rem 0.125rem rgba(0,0,0,0.15);
    top: -33rem;
    color: var(--color-near-black);
}

    .pesonalise-main-container.helpbot-pesonalise-main-container {
        left: -44rem;
        height: 33rem
    }

.pesonalise-main-container-small {
    width: 12.5rem;
    height: 10vh;
    background: var(--color-tile-white);
    border-radius: 0.4375rem;
    -webkit-box-shadow: 0 0 1.875rem 0.125rem rgba(0,0,0,0.12), 0 0 1.25rem 0.125rem rgba(0,0,0,0.15);
    box-shadow: 0 0 1.875rem 0.125rem rgba(0,0,0,0.12), 0 0 1.25rem 0.125rem rgba(0,0,0,0.15);
    position: absolute;
    left: -6.188rem;
    top: 3.1rem;
    color: var(--color-near-black);
    font-size: 1.063rem;
}

.RCM_HelpBot_Container {
    margin-top: 1rem;
    padding-left: 1rem;
    border-radius: 0;
    width: 60rem
}

.RCM_HelpBot_Close {
    position: absolute;
    right: -9.8rem;
    cursor: pointer;
    top: -0.1rem;
}

.RCM_Bot_Div {
    width: 44.25rem;
    /*padding-right: 0.9375rem;
    padding-left: 0.9375rem;*/
    margin-right: auto;
    margin-left: auto;
    border-style: solid;
    border-color: #B4BEFF;
    border-width: 0.125rem;
    background-color: #191f28;
}

.RCM_Help_IFrame_Container {
    left: -0.9rem;
    top: 0.3rem;
}


/* Asset aggregation webcomponent  */

/* .consent_popup {
    z-index: 9;
} */

.ConsentApp {
    z-index: 0;
}

.consent_popup {
    height: 100%;
    width: 100%;
    position: relative;
    left: 0%;
    top: 0%;
    transform: none;
    max-width: 100%;
    min-width: 100%;
    min-height: 620px;
    overflow: hidden;
    -webkit-box-shadow: 1px 5px 14px 0 rgba(0,0,0,.23);
    box-shadow: 1px 5px 14px 0 rgba(0,0,0,.23);
    border-radius: 3px;
}

/* .fi_list .search_wrpr {
    position: absolute;
}

.fi_list .search_wrpr.searching{
    bottom: 0;
    transform: none;
} */

.fi_list .page_cntnt {
    height: 100%;
    min-height: 620px;
    max-height: 620px;
}

.page_wrpr.fi_login .page_cntnt .btns_wrpr,
.page_wrpr.accounts_list .page_cntnt .btns_wrpr,
.consent_details .page_cntnt .btns_wrpr,
.page_wrpr.confirmation .page_cntnt .btns_wrpr {
    position: relative;
}

.fi_list .search_wrpr .inner_wrpr .inpt_wrpr input,
.fi_list .search_wrpr .inner_wrpr .inpt_wrpr {
    max-width: 100%;
}


.page_loader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 999;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}


    .page_loader .loading_icon {
        height: 50px;
        width: 50px;
        border-radius: 20px;
    }

.df.close_csnt_popup {
    position: absolute;
}

.consent_app_wrpr {
    position: relative !important;
}

.page_wrpr {
    min-height: 620px;
}


/* End of asset aggregation */
/*Footer Style start*/
#page-container {
    position: relative;
    min-height: 100vh;
    background-color: #0e1319;
}

#content-wrap {
    padding-bottom: 3.7rem; /* Footer height */
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.7rem;
    padding: 0;
}

.RCM_footer_container {
    background-color: #242a32;
    height: 3.7rem;
    width: 100%;
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

.RCM_footer_text_container {
    border-left: 0.0625rem solid transparent;
    border-image: linear-gradient(to bottom, transparent 0%,transparent 15%, #4e545a 16%, #4e545a 85%, transparent 86%, transparent 100%);
    border-image-slice: 1;
}

    .RCM_footer_text_container:first-child {
        border-left: 0;
    }

.RCM_footer_text, a.RCM_footer_text:hover {
    color: #ececec;
    height: 1rem;
    font-family: "Avenir-Regular";
    font-size: 0.75rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-left: 1.563rem;
    text-decoration: none;
}

    .RCM_footer_text:last-child {
        margin-right: 1.563rem;
    }

.RCM_footer_mail_text {
    padding-top: 0.3rem;
    /*cursor: pointer;*/
}
/*Footer Style end*/

/*Non Supported Browser*/
.RCM_nonsupportedbrowser {
    margin-top: 20%;
    margin-left: 20%;
    margin-right: 20%;
    width: 50%;
}

/*End Non supported browser*/

.RCM_two_level_table1.RCM_TaxDocument_Table tbody tr td input, .RCM_two_level_table1.RCM_TaxDocument_Table thead tr th input {
    position: unset;
}

.RCM_two_level_table1.RCM_TaxDocument_Table tbody tr td:nth-child(1), .RCM_two_level_table1.RCM_TaxDocument_Table thead tr th:nth-child(1) {
    text-align: center;
    padding-top: 1rem
}

.RCM_two_level_table1.RCM_TaxDocument_Table tbody tr td:nth-child(1) {
    width: 5%;
}

.modal-text-color {
    color: #212529;
}

.label-disable {
    opacity: 0.7;
    cursor: default;
}

.RCM_Watermark {
    position: fixed;
    left: 25%;
    top: 40%;
    opacity: 0.2;
    z-index: 99;
    color: white;
    font-size: 3rem;
    transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
}

.RCM_Watermark_Balance {
    width: 80%;
    height: 100%;
    position: absolute;
    left: 9%;
    opacity: 0.2;
    z-index: 99;
    color: gray;
    font-size: 3rem;
    transform: rotate(-30deg);
    -webkit-transform: rotate(0deg);
}
/*Start Manage Groups*/
.RCM_two_level_table1 thead tr th.RCM_manage_groups_add_btn {
    color: #16a5b0;
    cursor: pointer;
}

/*.RCM_manage_group_account_table input[type="checkbox"] {
    position: unset;
    left: 0;
}*/

.RCM_manage_group_saveBtn_container {
    margin-top: 0.625rem;
    text-align: center;
}

.RCM_two_level_table1 tbody tr.RCM_grp_selection_row {
    border: 0.125rem solid #16a5b0;
}

.RCM_grp_table.RCM_two_level_table1 thead tr:first-child th:first-child {
    pointer-events: none;
}

.RCM_manage_grp_delete_icon {
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
    background-color: var(--color-white);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('./images/delete.svg');
}

.RCM_manage_grp_arrow {
    height: 1.1rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
    margin-right: 0.6rem;
    background-image: url('./images/arrow-right.svg');
}

.RCM_manage_grp_arrow_down {
    height: 0.7rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
    margin-right: 0.6rem;
    background-image: url('./images/arrow-down.svg');
}

.RCM_manage_groups_delete_col {
    width: 1.4rem;
}

/* .RCM_manage_groups_name_col{
    width: 50.7rem !important;
} */

.RCM_two_level_table1 tbody tr td.RCM_manage_groups_number_col {
    padding-left: 4rem;
}

.RCM_manage_groups_arrow_col {
    width: 7rem;
}

/* .RCM_grp_table tbody tr.RCM_grp_table_row td:first-child {
    width: 2rem;
} */

.RCM_grp_table th[data-row-selection] {
    width: 7rem;
}

.RCM_manage_group_header {
    border-bottom: none;
}

.RCM_manage_group_footer {
    border-top: none;
}

.RCM_manage_groups_empty_col {
    width: 6.5rem;
}

.RCM_ManageGroup_heading_container {
    margin-bottom: 1.25rem;
}

.RCM_edit_cell_container {
    display: flex;
    justify-content: space-between;
}

 .RCM_edit_icon {
    height: 1.3rem;
    width: 1.3rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
    margin-right: 0.6rem;
    background-image: url('./images/Edit.svg');
}

.RCM_ManageGroup_Tables {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.RCM_manage_grp_new_group {
    color: #16a5b0;
    font-size: 0.8rem;
}
/*End Manage Groups*/

.RCM_Prospect_Disable_Button {
    pointer-events: none;
}

.RCM_CM_Toast {
    padding: 3.875rem 2.188rem !important;
}

.RCM_FAName {
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    color: #ffffff;
    margin-bottom: 0rem;
    word-wrap: break-word
}

.RCM_FAInfo {
    font-size: 0.9375rem;
    color: #ffffff;
}

.RCM_AdvisorTeam_Label {
    font-weight: 500;
    letter-spacing: 0.9px;
    font-size: 1rem;
    text-transform: uppercase;
}

.RCM_FADetails_Div {
    padding-left: 1rem
}

.RCM_success_msg {
    color: lightgreen;
}

.RCM_FA_Phone {
    background-image: url('./images/Phone_dark.svg');
}

.RCM_FA_Email {
    background-image: url('./images/Envelope_dark.svg');
}

/* Wheels Up  */

.RCM_aviation_base_image {
    height: 32rem;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('./images/wu-bgimg.png');
}

.RCM_aviation_mail {
    background-image: url('./images/aviation_mail.svg');
    height: 6rem;
    width: 6rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
}

.RCM_aviation_plane {
    height: 6rem;
    width: 6rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('./images/aviation_plane.svg')
}

.RCM_aviation_clipboard {
    height: 6rem;
    width: 6rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('./images/aviation_clipboard.svg')
}

.RCM_aviation_individual {
    height: 6rem;
    width: 6rem;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('./images/aviation_person.svg')
}

.RCM_aviation_toast_heading_container {
    min-height: 3rem;
}

.RCM_aviation_toast_content_container {
    min-height: 8rem;
}

.RCM_button_aviation_page, .RCM_button_aviation_page:hover, .RCM_button_aviation_page:disabled {
    color: #fff;
    /*width: 15.5rem;
    height: 2.5rem;*/
    border-radius: 1.375rem;
    background-color: #16a5b0;
    font-size: 0.75rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-align: center;
    border: none;
    font-family: 'Avenir-DemiBold';
    min-height: 3rem;
}

.RCM_TaxDocument_PrintAllBtn_Padding {
    padding-left: 7rem
}

/* End of wheels up */

/* Logon Form */

.RCM_Logon_Form_Logo {
    max-height: 36px;
}

.RCM_Form_Sec_Button {
    height: 32px;
    border: none;
    background-color: #ccc;
    background-color: rgba(0,0,0,0.2);
    min-width: 30%;
}

.RCM_Form_Primary_Button {
    height: 32px;
    border: none;
    color: #fff;
    border-color: #0067b8;
    background-color: #0067b8;
    min-width: 30%;
}

.RCM_Form_textfield {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
    border: 0;
    border-bottom: 1px solid;
    width: 100%;
    padding: 3px 0px 1px;
    border-width: 1px;
    border-color: #666;
    border-color: rgba(0,0,0,0.6);
    height: 36px;
    outline: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    background-color: transparent;
    color: gray;
}

.RCM_Form_textfield_error {
    border-color: red;
}

.RCM_Logon_Form_Footer {
    background-color: rgb(242, 242, 242);
    box-sizing: border-box;
    color: rgb(27, 27, 27);
    direction: ltr;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
    margin-top: 56px;
    overflow-wrap: break-word;
    padding: 24px 10% 36px 10%;
    text-align: left;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

    .RCM_Logon_Form_Footer div {
        color: #1b1b1b;
    }

.RCM_Italic {
    font-style: normal;
}




.RCM_background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/illustration.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.RCM_background_overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.55);
}


.RCM_login_outer {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
}

.RCM_login_middle {
    display: table-cell;
    vertical-align: middle;
}

.RCM_login_inner {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 440px;
    width: 100%;
    padding: 44px;
    margin-bottom: 28px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    min-width: 436px;
    min-height: 440px;
    overflow: hidden;
}

/* End Login form */

/*Manage Asset start*/
.RCM_addAsset_btn_container {
    padding-top: 1.4rem;
}

.RCM_manageAsset_btn, .RCM_manageAsset_btn:hover, .RCM_manageAsset_btn:disabled, .RCM_manageAsset_btn:visited, .RCM_manageAsset_btn:active:focus {
    width: 10rem;
}

.RCM_selected_asset_container {
    margin-bottom: 1.125rem;
}

@media (min-width: 576px) {
    .modal-dialog.RCM_externalAssetWrapper {
        max-width: 62.5rem;
        margin: 1.75rem auto;
    }
}
/*Add manual entry for external asset in asset aggregation start*/
.RCM_externalAssetWrapper .modal-content {
    background-color: #191f28;
}

    .RCM_externalAssetWrapper .modal-content:hover,
    .RCM_externalAssetWrapper .modal-content:active,
    .RCM_externalAssetWrapper .modal-content:focus {
        border: 1px solid rgba(0,0,0,.2);
    }

    .RCM_externalAssetWrapper .modal-content .modal-body {
        padding-top: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: .5rem;
    }

    .RCM_externalAssetWrapper .modal-content .modal-footer {
        border-top: none;
        padding: 0rem;
    }

    .RCM_externalAssetWrapper .modal-content .modal-header .modal-title {
        padding-top: 0.5rem;
        padding-left: 0.5rem;
    }

    .RCM_externalAssetWrapper .modal-content .modal-header {
        border: none;
    }

a.RCM_addExternallink {
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    font-family: "Avenir";
    font-size: 0.875rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.6px;
    color: #fff;
    border-bottom: 1px solid #ececec;
}

.RCM_externalAssetModel_header.modal-header {
    border: 0;
}

.RCM_addExternalAssetLink {
    text-align: right;
}

.RCM_backLink {
    text-align: right;
    margin-bottom: 1rem;
}

.RCM_backLink_container {
    justify-content: flex-end;
}

.RCM_externalAssetWrapper .RCM_form_control:focus {
    color: #ececec;
    background-color: #2a3641;
    border: none;
    outline: 0;
    box-shadow: none;
    font-family: "Avenir-Regular";
}

input.RCM_externalasset_Datepicker {
    font-size: 0.9375rem;
    background-image: url(./images/calendar.svg);
    background-color: #2a3641;
    color: #ececec;
    border: none;
    background-repeat: no-repeat;
    padding-left: 2rem;
    display: block;
    width: 100%;
    background-position: left;
    border-radius: .25rem;
}

.RCM_externalAssetModel label {
    font-family: "Avenir-Regular";
    color: #ececec;
    letter-spacing: 0.1367rem;
    font-size: 12px;
    text-transform: uppercase;
}

    .RCM_externalAssetModel label.datepickerlabel {
        margin-left: 1rem;
    }

.externalAssetbtn {
    text-align: center;
}
/*Add manual entry for external asset in asset aggregation end*/

.RCM_NavTab_container .nav-link.linkactive.RCM_manage_asset_navlink, .RCM_NavTab_container .nav-link.linkactive.RCM_manage_asset_navlink:hover {
    font-family: "Avenir-DemiBold";
    border: 0;
    background-color: #191F28;
    border-bottom: 0.125rem solid #16a5b0;
    color: #16a5b0;
}

.RCM_select_asset_container {
    margin-bottom: 1.25rem;
    display: flex;
}

    .RCM_select_asset_container .RCM_CM_form_label {
        width: 30%
    }

    .RCM_select_asset_container .RCM_select_box {
        width: 70%
    }

.RCM_asset_form_container {
    text-align: left;
    width: 80%;
    display: inline-table;
}

.RCM_asset_tab_container {
    display: block;
}

    .RCM_asset_tab_container .nav.nav-tabs {
        justify-content: center;
    }

.RCM_asset_form_btn_container {
    text-align: right;
    margin-top: 0.5rem;
}

    .RCM_asset_form_btn_container .RCM_manageAsset_btn {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

.RCM_ManageAsset_Table .RCM_two_level_table1 thead tr th:last-child, .RCM_ManageAsset_Table .RCM_two_level_table1 tbody tr th:last-child {
    width: 15%;
    text-align: right;
}

.RCM_manageasset_icon_position {
    width: 100%;
    background-position: center top;
}
/*Manage Asset end*/
.toolTip {
    font-size: 0.75rem;
    background-color: #252f39;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    color: #fff;
    width: 24rem !important;
    max-width: 100% !important;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #252f39;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #252f39;
}


.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #252f39;
}

.leftTextAlign {
    text-align: left
}

.content-mobile {
    display: none;
}

.RCM_Security_lbl {
    width: 7.875rem;
    height: 0.9375rem;
    font-family: "Avenir";
    font-size: 0.6875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ececec;
}

.RCM_select_box.RCM_mm_subselectModal_box.RCM_Document_Dropdown.dropdown .dropdown-toggle.btn.btn-secondary {
    background-color: #191f28;
}

.RCM_right_text_allign {
    text-align: right
}

.RCM_downloadall_width {
    width: 13rem
}

.RCM_add_asset_link_color {
    color: #16a5b0;
}

.RCM_open_in_app {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 440px;
    padding: 14px;
    margin-bottom: 28px;
    background-color: #252f39;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    min-width: 436px;
    overflow: hidden;
}

.RCM_Logon_App_Logo {
    max-height: 55px
}

/* RCM estimated income tables */

.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(3),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(3),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(3),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(4),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(4),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(4),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(5),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(5),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(5),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(6),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(6),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(6),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(7),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(7),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(7),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(8),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(8),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(8),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(9),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(9),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(9),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(10),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(10),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(10),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(11),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(11),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(11),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(12),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(12),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(12),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(13),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(13),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(13),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(14),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(14),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(14) {
    width: 6%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

/*.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(even),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(even),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(even) {
    width: 6.3%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}*/

.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:first-child,
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:first-child,
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:first-child,
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(15),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(15),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(15) {
    width: 8%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tbody tr td:nth-child(2),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 tfoot tr th:nth-child(2),
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table1 thead tr th:nth-child(2) {
    width: 12%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}


.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(5)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(6)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(7)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(8)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(9)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(10)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(11)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(12)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(13)
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(14) {
    width: 6.2%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}


.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:first-child
.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(15) {
    width: 7%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr td:nth-child(2) {
    width: 11%;
    padding-right: 0;
    white-space: normal;
    word-break: break-all;
}

.RCM_left_bar_toggle.RCM_EstiamtedIncomeSummary_toggle_button_width {
    width: 2rem;
}

ul.RCM_Estimation_total_legend {
    border: 0.005rem solid;
    padding: 0.5rem;
    margin-top: 2.5rem;
}

.RCM_EstimatedIncomeSummary_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: auto;
}

.RCM_Realized_SecurityHolding_Wrapper .table.RCM_two_level_table2.RCM_Headerless_table tbody tr {
    cursor: auto;
}
/* estimated Income */
.RCM_PopOut {
    background-image: url('./images/PopOutLight.svg');
}


.RCM_Collabortaion_icon {
    margin-right: 0.1rem;
    padding: 0.01rem;
}

.RCM_Collaboration_image {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}


/* Bar Chart*/

.RCM_BarChart_Total_Label {
    font-size: 0.85rem;
}


/* Bar chart*/
.RCM_Liability_li {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}

/*Bill pay start*/
.RCM_payment_plateform_heading_wrapper {
    display: flex;
    justify-content: center;
}

.RCM_payment_plateform_heading {
    font-size: 28px;
    padding-top: 2%;
}

.RCM_Mytask_wrapper {
    text-align: center;
}

.RCM_payments_toast_heading {
    font-size: 1.125rem;
}

.RCM_payments_btn, .RCM_payments_btn:hover, .RCM_payments_btn:disabled, .RCM_payments_btn:visited, .RCM_payments_btn:active:focus {
    width: 9rem;
    margin: 0 1.25rem;
}

.RCM_clientName_col {
    width: 25%
}

.RCM_Vendor_Action_col {
    width: 17%;
}

.RCM_common_table_column_text_right {
    text-align: right;
}

.RCM_uploadInvoice_label {
    margin-right: 1.5rem;
    margin-bottom: 0;
    margin-left: 1rem;
}

.RCM_dragAndDrop_wrapper {
    border: 1px solid #fff;
    height: 10rem;
    padding: 3.5rem;
    margin: 0 0 2.5rem 1rem;
}

.RCM_dragAndDrop_text {
    font-size: 1.25rem;
}

.RCM_Billpay_upload_btn_container {
    display: flex;
    justify-content: center;
}

.RCM_billpay_back_btn_container {
    justify-content: flex-end;
}

.RCM_payments_btn_wire, .RCM_payments_btn_wire:hover, .RCM_payments_btn_wire:disabled, .RCM_payments_btn_wire:visited, .RCM_payments_btn_wire:active:focus {
    width: 13rem;
}

.RCM_Billpay_NavTab_container {
    display: flex;
    place-items: normal;
    text-align: left;
    padding: 0
}

.RCM_Billpay_navlink {
    padding: 1rem 1rem;
    font-size: 0.875rem;
}

.RCM_NavTab_container .nav-link.linkactive.RCM_manage_asset_navlink.RCM_Billpay_navlink, .RCM_NavTab_container .nav-link.linkactive.RCM_manage_asset_navlink.RCM_Billpay_navlink:hover {
    border-bottom: 0;
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.31);
    background-color: #252f39;
}

.RCM_asset_form_container.RCM_billPay_from_container {
    text-align: left;
    width: 100%;
    display: inline-table;
}

.RCM_bank_loc_container {
    margin-bottom: 1rem;
}

.RCM_bank_loc_text {
    color: #ffffff;
    letter-spacing: 0.1667rem;
    margin-top: 0.625rem;
    font-size: 0.75rem;
}

input[type="radio"] + span.RCM_radio_span.RCM_billPay_radio_span {
    width: 100%;
    word-break: break-all;
}

.RCM_bank_paymentType_text {
    color: #ffffff;
    letter-spacing: 0.1667rem;
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
    padding-left: 2rem;
}

.RCM_billpay_toggleBtn {
    text-align: right;
}

.RCM_bank_autopaysetup_text {
    color: #ffffff;
    letter-spacing: 0.1667rem;
    margin-top: 0.75rem;
    font-size: 0.75rem;
}

.RCM_BillpayHeaderWrapper {
    display: flex;
    justify-content: space-between;
}

.RCM_enterBillBtn_wrapper {
    justify-content: flex-end;
    display: flex;
}

.RCM_BillPayments_table_Wrapper {
    margin-top: 1rem;
}

.RCM_edit_cell {
    height: 1.1rem;
    width: 5.625rem;
}

.RCM_billpay_form_heading {
    font-size: 1.063rem;
    margin: 0.5rem 0 0.5rem 0;
}

.RCM_billpay_btnPadding {
    margin-right: 1rem;
}


.RCM_billpay_form_divider {
    margin-bottom: 1rem;
    background-color: #20252d;
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.31);
    padding: 1rem;
}

.RCM_billpay_form_divider_nobackground {
    margin-bottom: 0.5rem;
    padding: 1rem;
}

.RCM_wirepurpose_container {
    margin-top: 1rem;
}

.RCM_typeahead input, .RCM_typeahead input:focus, .RCM_typeahead input:active, .RCM_typeahead input:active:focus {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    width: 100%;
    border: 0;
    color: #ececec;
    font-size: 0.875rem;
    width: 100%;
    height: 2.5rem;
    border: 0;
    box-shadow: none;
}

.RCM_typeahead .dropdown-menu.show {
    background-color: #252f39;
    padding: 0.5rem 2rem;
    margin-bottom: 1px;
}

.RCM_typeahead .dropdown-menu li, .RCM_typeahead .dropdown-menu li:hover {
    color: #ececec;
    font-size: 1.063rem;
    border-bottom: 0.5px solid var(--color-near-light-grey);
    line-height: 2.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #252f39;
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.29;
    letter-spacing: 0.5px;
    border-bottom: 0.0625rem solid #4e545a;
}

    .RCM_typeahead .dropdown-menu li .dropdown-item.active, .RCM_typeahead .dropdown-menu li .dropdown-item:active, .RCM_typeahead .dropdown-menu li .dropdown-item:hover {
        color: #ececec;
        background-color: #252f39;
    }

.RCM_label_wrapper {
    display: flex;
}

    .RCM_label_wrapper .RCM_validation_error_container {
        margin-top: 0;
        padding: 0.625rem 0.625rem 0 0.625rem;
    }

.RCM_Bulk_PayfromPadding {
    padding-left: 0.8rem;
}

.RCM_All_Bills_Dropdown button.dropdown-toggle.btn.btn-secondary {
    margin-right: 0.5rem;
}

.RCM_payments_link {
    color: #fb0000;
    cursor: pointer;
}
/*Bill pay end*/

/*Accounting start*/
.RCM_subMenu{
    width:13%;
}
.RCM_subMenu_body {
    width: 87%;
}
.RCM_subMenu .ant-tabs-nav .ant-tabs-tab {
    font-size: 0.75rem;
}
.RCM_subMenu .ant-tabs .ant-tabs-left-bar {
    border-right: 0;
}
.RCM_subMenu.btn.btn-secondary {
    color: #fff;
    background-color: transparent;
    border: none;
    padding-left: 0;
    width: 100%;
    text-align: left;
}
/*Accounting end*/
/*Agreement start*/
.RCM_table_overflow_cell {
    word-break: break-all;
}
/*Agreement edn*/
/*Media Query Start*/
@media (max-width: 1366px) {
    .RCM_toggleWrapper {
        margin-left: -0.3rem;
    }
}

@media (max-width: 1450px) {
    .RCM_withFADetails div.RCM_fo_name {
        border-bottom: 0;
        line-height: normal;
        margin: 0;
    }

    div.RCM_fo_name span {
        padding: 0;
    }
}

@media (max-width: 1680px) {
    .nav-item a.RCM_menu_text {
        text-align: left;
    }

    .RCM_Nav_wrapper_Help a.RCM_menu_text .RCM_nav_text {
        padding-top: 0rem;
    }

    .REM_aaflex-list .dropbottom button {
    }

    .RCM_Home_PrintExport {
        width: 8rem;
    }

    .RCM_fontsizing_wrapper {
        top: 0.8rem;
    }

    .RCM_FAInfo {
        font-size: 0.8rem;
    }
}

@media (max-width: 1280px) {
    .RCM_settingWrapperUpper {
        display: block;
    }

    .RCM_toggleWrapper {
        margin: 1rem 0 1rem 0;
    }

    .RCM_settingsWrapper {
        padding-right: 8rem;
        padding-top: 0.4375rem;
    }

    #AssetAllocationSummary {
        padding: 1rem;
    }

    .REM_aaflex-list .dropbottom button {
        padding: 1.2rem 0.8rem;
    }

    .nav-item a.RCM_menu_text {
        padding: 1.2rem 0.8rem;
        text-align: left;
    }

    .RCM_Nav_wrapper_Help a.RCM_menu_text {
        padding: 0rem 0.8rem 0.8rem 0.8rem;
    }

        .RCM_Nav_wrapper_Help a.RCM_menu_text .RCM_nav_text {
            padding-top: 0rem;
        }

    .RCM_Download {
        width: 1.2rem;
        height: 1rem;
    }

    .RCM_userMenu_Refresh {
        width: 1.2rem;
        height: 1.2rem;
        margin-top: 0.85rem;
    }

    .RCM_userMenu_Print {
        width: 1.2rem;
        height: 1.2rem;
    }

    .RCM_Home_PrintExport {
        width: 6rem;
        margin: 0 0rem 0.8rem 0;
    }

    .RCM_fontsizing_wrapper {
        top: 0.8rem;
    }

    .RCM_holdingvalue {
        font-size: 0.625rem;
    }

    .arrow-up, .arrow-down {
        margin-top: 0.9rem;
    }

    .RCM_holdingWrapper {
        padding-left: 0rem;
    }

    .RCM_Sort_arrow_up_icon, .RCM_Sort_arrow_down_icon, .RCM_Sort_arrow_icon {
        width: 0.625rem;
        background-size: 0.65rem;
        margin-left: 0.25rem;
    }

    .RCM_asset_label {
        font-size: 10px;
    }

    .RCM_toptentable_wrapper {
        padding-left: 0;
    }

    .RCM_FAInfo {
        font-size: 0.75rem;
    }
}

.RCM_billpay_form_box {
}

.RCM_billpay_form_content {
    padding-left: 2rem;
    padding-right: 2rem;
}

    .RCM_billpay_form_content label {
        font-family: "Avenir";
        font-size: 1rem;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.6px;
        padding-top: 0.2rem;
    }

@media (min-width: 992px) {
    .col-lg-7.RCM_withFADetails {
        -ms-flex: 0 0 52.333333%;
        flex: 0 0 52.333333%;
        max-width: 52.333333%;
    }
}

@media (min-width: 1550px) {
    .col-lg-3.RCM_logosettingwrapper {
        -ms-flex: 0 0 21.5%;
        flex: 0 0 21.5%;
        max-width: 21.5%;
    }
}

@media (max-width: 1279px )and (min-width: 1190px) {
    .col-lg-6.RCM_headerbalance.RCM_logosettingwrapper_balances.RCM_withFADetails {
        -ms-flex: 0 0 54%;
        flex: 0 0 54%;
        max-width: 54%;
    }

    .col-lg-3.RCM_logosettingwrapper.RCM_withFADetails {
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
    }
}

@media (max-width: 1189px )and (min-width: 992px) {
    .col-lg-6.RCM_headerbalance.RCM_logosettingwrapper_balances.RCM_withFADetails {
        -ms-flex: 0 0 65%;
        flex: 0 0 65%;
        max-width: 65%;
    }

    .col-lg-3.RCM_logosettingwrapper.RCM_withFADetails {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (max-width: 1066px )and (min-width: 992px) {
    .RCM_preference_label_mail {
        padding: 0
    }
}

@media (max-width: 1365px) {
    .RCM_Profile {
        margin-left: 10rem;
        margin-right: 10rem;
    }
}

@media (max-width: 991px ) {
    .RCM_logosettingwrapper.RCM_withFADetails {
        border-right: 0;
    }

    .RCM_logosettingwrapper {
        margin: 0;
    }

    .RCM_Nav_wrapper {
        background-color: #242a32;
    }

    .RCM_Profile {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

@media (max-width: 1089px ) {
    .RCM_logosettingwrapper_balances {
        border-right: 0;
    }
}

@media (max-width: 1300) {
    .RCM_withFADetails div.RCM_fo_name {
        border-bottom: 0;
        line-height: unset;
    }
}

@media (max-width: 700px) {
    .RCM_withoutFADetails div.RCM_fo_name, .RCM_withFADetails div.RCM_fo_name {
        border-bottom: 0;
        line-height: unset;
        margin: 0.3125rem 0 0;
    }

    .RCM_withoutFADetails .RCM_fo_value, .RCM_withFADetails .RCM_fo_value {
        margin: 0.3125rem 0 0;
    }
}

@media (max-width: 767px) {
    .RCM_TradeConfirm_Search {
        margin: 0.5rem 0 0 2%;
    }

    .RCM_TradeConfirm_DateRange_Search_Btn_Margin {
        margin-top: 0;
    }

    #RCM_correspondencetodate {
        margin-top: 0.5rem;
    }

    .RCM_Profile {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .content-mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 100%;
        z-index: 10
    }
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .content-mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 100%;
        z-index: 10
    }
}

/*Media Query end*/



.RCM_Loading_Logo {
    position: absolute;
    top: 28%;
    left: 40%;
    font-size: 20rem;
    padding: 0;
}

.RCM_Loading_Logo_Spinner {
    font-size: 4rem;
    position: relative;
    top: -5rem;
}

.collapse.show.navbar-collapse .navbar-nav > .nav-item {
    z-index: 2;
    background-color: #242a32;
    border-bottom: 1px solid #191f28;
    padding-left: 1rem;
}



/* File upload */

.RCM_File_Drop.ant-upload.ant-upload-drag {
    background: unset;
    color: #fff;
    height: 15rem;
    max-height: 15rem;
    overflow: hidden;
}

    .RCM_File_Drop.ant-upload.ant-upload-drag p.ant-upload-text,
    .RCM_File_Drop.ant-upload.ant-upload-drag p.ant-upload-hint {
        color: #fff;
    }

.RCM_File_Drop .ant-upload-list,
.RCM_File_Drop .anticon,
.RCM_File_Drop .anticon.anticon-close {
    color: #fff;
}

.ant-upload-list-item-info .anticon-loading {
    color: #fff;
}

.ant-upload-list-item-info, .ant-upload-list-item-name {
    color: #fff;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: unset;
}

.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-text {
    color: #fff;
}

.ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-info .anticon-paper-clip {
    color: #fff;
}

.ant-upload-list-item-error .anticon-paper-clip {
    color: #f5222d;
}



.RCM_File_Drop.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: aqua;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #13A5B0;
}




.RCM_billpay_btn {
    border: 0.5px solid #fff;
    display: inline-block;
    padding: 3px 6px;
    cursor: pointer;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background: transparent;
    margin-left: 0.2rem;
}

    .RCM_billpay_btn:hover, .RCM_billpay_btn:active, .RCM_billpay_btn:focus {
        background-color: #2a3641;
        border: 0.5px solid #fff;
    }

    .RCM_billpay_btn:disabled{
        cursor:default;
        opacity:0.5;
    }
    /* The container */
    .RCM_Radio_Container {
        display: block;
        position: relative;
        padding-left: 2rem;
        margin-top: 1rem;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .RCM_Radio_Container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

/* Create a custom radio button */
.RCM_Radio_CheckMmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.RCM_Radio_Container:hover input ~ .RCM_Radio_CheckMmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.RCM_Radio_Container input:checked ~ .RCM_Radio_CheckMmark {
    background-color: #16a5b0;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.RCM_Radio_CheckMmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.RCM_Radio_Container input:checked ~ .RCM_Radio_CheckMmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.RCM_Radio_Container .RCM_Radio_CheckMmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.RCM_form_subheading {
    color: aqua;
}


.RCM_TaskPlanner_Drawer .ant-drawer-content {
    background: rgb(25,31,40, 0.95);
    color: #fff;
}

.RCM_TaskPlanner_Drawer .ant-drawer-header {
    background: rgb(25,31,40, 0.95);
    border: none;
    color: #fff;
}

.RCM_TaskPlanner_Drawer .ant-drawer-title {
    color: #fff;
}

.ant-drawer-close, .ant-drawer-close:hover {
    color: #fff;
}

.RCM_Taskplanner_Container {
    margin-top: 0.1rem;
}

mgt-tasks {
    background: transparent;
    --task-new-add-button-background: #16a5b0;
    --tasks-new-button-background: #16a5b0;
    --tasks-new-button-border: none;
    --tasks-new-button-hover-background: #16a5b0;
    --tasks-new-button-active-background: #16a5b0;
    --task-background: #252f39;
    --task-border: none;
    --task-complete-background: #252f39;
    --task-complete-border: none;
    --task-new-cancel-button-color: #fff;
    --tasks-new-line-border: none;
    --tasks-new-input-background: red;
}

mgt-agenda {
    --event-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.092);
    --event-margin: 0 0.75rem 0.5rem 0.75rem;
    --event-padding: 0.5rem 0;
    --event-background: #252f39;
    --event-border: solid 2px rgba(0, 0, 0, 0);
    --agenda-header-margin: 40px 10px 14px 10px;
    --agenda-header-font-size: 24px;
    --agenda-header-color: #fff;
    --event-time-font-size: 0.75rem;
    --event-time-color: #fff;
    --event-subject-font-size: 1.2rem;
    --event-subject-color: #fff;
    --event-location-font-size: 0.75rem;
    --event-location-color: #fff;
}

mgt-person {
    --avatar-size-s: 24px;
    --avatar-size: 32px;
    --avatar-font-size--s: 16px;
    --avatar-font-size: 16px;
    --avatar-border: 0;
    --initials-color: white;
    --initials-background-color: magenta;
    --font-size: 12px;
    --font-weight: 400;
    --color: white;
    --email-font-size: 12px;
    --email-color: white;
}

mgt-person-card {
    z-index: 10;
}

.RCM_Email_List {
    padding: 1.5rem;
    margin-top: 0.5rem;
    background: #252f39;
    position: relative;
    min-height: 10rem;
}

.RCM_Email_Subject {
    margin-top: 0.5rem;
}

.RCM_Email_Body {
    margin-top: 0.5rem;
}

.RCM_Selected_User_Row {
    background-color: cadetblue !important;
}

.RCM_Discr_form_container {
    padding: 1rem;
    box-shadow: 0.5rem 0.5rem 2rem rgb(0,0,0, 0.4);
    margin-bottom: 2rem;
    background-color: #20252d;
}

.RCM_Discr_form_main_heading {
    text-decoration: underline;
    text-underline-position: under;
}

.RCM_form_control_textarea {
    height: unset;
}

.RCM_Discr_form_label_heading {
    margin-bottom: 0;
}

.RCM_Discr_form .row {
    margin-bottom: 0.5rem;
}

.ant-calendar-picker-input.ant-input, .ant-calendar-picker-input.ant-input:focus {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    color: #ececec;
    border: none;
    border-radius: 0;
    font-size: 1rem;
    box-shadow: none;
}

.ant-calendar-input {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    color: #ececec;
    border: none;
    border-radius: 0;
    font-size: 1rem;
}

.ant-calendar-picker-input.ant-input:focus {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    color: #ececec;
    border: none;
    border-radius: 0;
    font-size: 1rem;
}

.ant-calendar-picker-icon {
    color: #fff;
}



.ant-calendar,
.ant-calendar-panel,
.ant-calendar-date-panel,
.ant-calendar-header,
.ant-calendar-input-wrap,
.ant-calendar-footer,
.ant-calendar-year-panel,
.ant-calendar-year-panel-header,
.ant-calendar-month-panel,
.ant-calendar-month-panel-header,
.ant-calendar-date-panel:hover,
.ant-calendar-date-panel:focus,
.ant-calendar-date-panel:active,
.ant-calendar-picker:hover {
    font-family: "Avenir-Regular";
    background-color: #2a3641;
    color: #ececec;
    border-radius: 0;
    border: none;
}

.ant-calendar-date {
    color: #ececec;
}

    .ant-calendar-date:hover {
        background-color: #13A5B0;
        color: #2a3641;
    }

.ant-calendar-last-month-cell .ant-calendar-date, .ant-calendar-next-month-btn-day .ant-calendar-date, .ant-calendar-last-month-cell .ant-calendar-date:hover, .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
    color: #ececec;
}

.ant-calendar-selected-day .ant-calendar-date {
    background-color: #13A5B0;
    color: #2a3641;
}

.ant-calendar-today .ant-calendar-date {
    border-color: #13A5B0;
    color: #ececec;
}

.ant-calendar-picker-clear, .ant-calendar-picker-clear:hover {
    color: #ececec;
    background-color: #2a3641;
}

.ant-calendar-disabled-cell .ant-calendar-date {
    background: #6c757d;
    color: #fff;
}

    .ant-calendar-disabled-cell .ant-calendar-date:hover {
        background: #6c757d;
    }

.ant-calendar-next-month-btn:hover,
.ant-calendar-next-year-btn:hover,
.ant-calendar-prev-year-btn:hover,
.ant-calendar-prev-month-btn:hover {
    color: #16a5b0;
}

.ant-calendar-month-panel-cell.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-year-panel-cell.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-cell.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year:hover,
.ant-calendar-year-panel-cell .ant-calendar-year-panel-year:hover,
.ant-calendar-month-panel-cell .ant-calendar-month-panel-month:hover {
    background-color: #13A5B0;
}

.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-in-range-cell::before,
.ant-calendar-range-picker-separator {
    background: unset;
    color: #ececec;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
    color: #fff;
    background: #13A5B0;
    border: 1px solid transparent;
}


.RCM_Discr_radio_container > label {
    padding-left: 0.3rem;
    margin-right: 1rem;
}

.RCM_NavBar_Width {
    width: 99%;
    padding-bottom: 1rem;
}

    .RCM_NavBar_Width .navbar-nav {
        margin-top: 1rem;
    }

.RCM_Table_Pdf_icon {
    background: url('./images/icons8-pdf-30.png') no-repeat;
    height: 2rem;
    width: 2rem;
    padding: 0rem;
    border: 0rem;
    cursor: pointer;
}

.ant-tabs-bar {
    border-bottom: none;
}

.ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
}

.ant-tabs-nav .ant-tabs-tab {
    color: aqua;
    font-weight: 500;
    letter-spacing: .1667rem;
}

    .ant-tabs-nav .ant-tabs-tab:hover {
        color: aqua;
    }

.ant-tabs-ink-bar {
    background-color: aqua;
}

.RCM_Not_Found_Container {
    font-size: larger;
}

.ant-result-title, .ant-result-subtitle {
    color: #ececec;
}


.ant-layout {
    background: none;
}

.ant-layout-header {
    padding: 0 25px;
}

/* .ant-layout-footer{
    color: rgba(255, 255, 255, 0.65);
    background: #001529;
  } */

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #13A5B0;
}

.ant-dropdown-menu {
    background-color: #252f39;
}

.ant-dropdown-menu-item,  
.ant-dropdown-menu-submenu-title {
    color: #fff;
}

.ant-dropdown-menu-item:hover, 
.ant-dropdown-menu-submenu-title:hover {
    color: black;
}

.ant-dropdown-menu-item-group-title{
    color: aqua;
}

.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: aqua;
}


.RCMPaymentHub_Logo {
    float: left;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    font-weight: 500;
}

.RCM_User_Icon, .RCM_User_Icon:hover {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}


#RCM_PaymentsDashboard_Table, #RCM_Vendors_Table,
#RCM_Admin_ClientVisibility_List_Table,
#admin_clientvisibility_userlist, #admin_wireslist {
    width: 100%;
    overflow-x: scroll;
}

.ant-message .anticon {
    top: -2px;
}

.ant-message-notice-content .ant-message-custom-content.ant-message-info span,
.ant-message-notice-content .ant-message-custom-content.ant-message-error span {
    color: black;
}

.RCM_Form_Label {
    color: #fff;
}

.RCM_discretion_distribution_form_checkbox {
    position: relative;
    top: 0.6rem;
    margin-right: 0.5rem;
}


.ant-steps {
    width: 80%;
}

.ant-steps-item-icon {
    background-color: #13A5B0;
    color: #fff;
}

    .ant-steps-item-icon > .ant-steps-icon {
        color: #fff;
    }

.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    color: #fff;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: aqua;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #13A5B0;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: green;
    border-color: transparent;
}

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: aqua
    }

.RCM_Not_Approved_text {
    color: #b0c4de;
}

/*CSS for subaccounts start*/
.RCM_subAccounts_subHeading {
    margin: 0 0 1.5rem 0;
}

.RCM_tile_heading_lbl_subAccount {
    margin-bottom: 1.5rem;
}

.RCM_ninthWave_popup .RCM_Aggr_Fi_Container {
    max-height: calc(130vh - 30em);
    overflow: scroll;
}
.RCM_ninthWave_popup .RCM_Aggr_Login_Container {
    max-height: calc(139vh - 30em);
    overflow: auto;
    min-height: 35rem;
}
/*.RCM_SubAccounts_Table .react-bootstrap-table {
    max-height: 30rem;
    overflow: scroll;
}*/

.RCM_link_disabled, .RCM_Rules_control_text:disabled, .RCM_approverEmail:disabled {
    cursor: default;
    opacity: 0.5;
}
.RCM_Rules_control_notDisabled:disabled {
    opacity: 1;
}
.RCM_table_scorllable_body tbody {
    display: block;
    max-height: 10rem;
    overflow: auto;
}

    .RCM_table_scorllable_body thead, .RCM_table_scorllable_body tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

.RCM_SubAccounts_Table tbody {
    max-height: calc(120vh - 38em);
    min-height: 8rem;
}
.RCM_subAccount_btn_wrapper{
    margin-top: 1rem; 
}
.RCM_accounting_btn, .RCM_accounting_btn:active:focus, .RCM_accounting_btn:hover, .RCM_accounting_btn:disabled {
    width: 13rem;
}
.RCM_Rules_FormGroup, .RCM_display_flex{
    display:flex;
}
.RCM_popup_btn, .RCM_popup_btn:active:focus, .RCM_popup_btn:hover {
    padding: 0 2.5rem;
    width: auto;
}
.RCM_Rules_control.RCM_form_control {
    width: auto;
    min-width: 15rem;
    margin-right: 1rem;
}
.RCM_dropdown_partyID{
    width: 15rem;
}
.RCM_Rules_control_text.RCM_form_control {
    width: 22rem;
    margin-right: 1rem;
}

.RCM_Rules_label {
    margin-right: 1rem;
    padding-top: 0.25rem;
}
.RCM_rule_value_lbl {
    min-width: 7rem;
    margin-right: 1rem;
}
.RCM_rules_lbl_fields_wrapper{
    /*justify-content:space-between;*/
}
.RCM_rules_lbl_fields_btn{
    margin-right:12rem;
}
.RCM_rules_lbl_fields_btn_wrapper {
    padding: 0.45rem 0;
}
.RCM_payee .RCM_rules_lbl_fields_btn_wrapper {
    margin-right:1.5rem;
}
.RCM_mm_select_box.RCM_Rules_control.RCM_link_disabled button{
    cursor: default;
}
.RCM_rule_section{
    margin-top: 1.5rem;
}
.RCM_Textbox_close-icon {
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    position: absolute;
}

    .RCM_Textbox_close-icon:hover,
    .RCM_Textbox_close-icon:active,
    .RCM_Textbox_close-icon:focus,
    .RCM_Textbox_close-icon:visited {
        border: 1px solid transparent;
        background-color: transparent;
        display: inline-block;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        position: absolute;
    }

    .RCM_Textbox_close-icon:after {
        content: "X";
        display: block;
        position: absolute;
        z-index: 1;
        right: 2rem;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0.5rem;
        text-align: center;
        color: white;
        font-weight: normal;
        font-size: 1rem;
        cursor: pointer;
    }
.RCM_form_control_search{ margin-top: 0 }
.RCM_Rules_Search_wrapper{
    margin-bottom:0.5rem;
}
@media (min-width: 576px) {
    .RCM_CM_modal_subaccounts.modal-dialog {
        max-width: 600px;
        
    }
}
@media (min-width: 992px) {
    .RCM_Posting_rules_modal.modal-lg{
        max-width: 1200px;
    }
}
    /*CSS for subaccounts end*/
    /* Rockefeller ninth waves custom component  */
    .RCM_Aggr_Fi_Container {
        height: 37rem;
        overflow-y: auto;
        overflow-x: hidden;
    }


.RCM_Aggr_FiList_Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
}

.RCM_Aggr_Fi {
    padding: 0.5rem;
    width: 12rem;
    max-width: 12rem;
    max-height: 10rem;
}

    .RCM_Aggr_Fi:hover {
        background-color: #242a34;
        cursor: pointer;
    }

    .RCM_Aggr_Fi > .RCM_Aggr_Fi_Name {
        padding-left: 0rem;
        padding-top: 1.3rem;
    }

.RCM_Aggr_Fi_Name {
    font-size: 0.85rem;
    text-transform: uppercase;
}

.RCM_Aggr_Fi_As_list {
    padding: 0.5rem;
    border-bottom: 1px solid rgb(64, 68, 79);
    display: flex;
    flex-wrap: wrap;
}

    .RCM_Aggr_Fi_As_list > .RCM_Aggr_Fi_Name {
        padding-left: 1rem;
        padding-top: 1.3rem;
    }

    .RCM_Aggr_Fi_As_list:hover {
        background-color: #242a34;
        cursor: pointer;
    }

.RCM_Aggr_Fi_Img {
    border: 1px solid #ddd;
    border-radius: 0.2rem;
    width: 4rem;
    height: 4rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.RCM_Aggr_NoFI {
    width: 90%;
    margin-top: 6rem;
}


.RCM_Aggr_Login_Container {
    padding: 2rem;
    min-height: 38rem;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 300px;
    padding-left: 20%;
    padding-right: 20%;
}

.RCM_Aggr_Login_logo_wrap {
    margin-bottom: 20px;
}

    .RCM_Aggr_Login_logo_wrap > img {
        height: 200px;
        width: 250px;
    }

.RCM_Aggr_Login_Controls {
    background-color: white;
    color: rgb(64, 68, 79);
    min-width: 10rem;
}

    .RCM_Aggr_Login_Controls > option {
        padding-left: 0.3rem;
    }

.RCM_Aggr_Login_Radio {
    position: relative !important;
    left: 0 !important;
}

.RCM_Aggr_login_form_select {
    display: none;
}


.RCM_Aggr_Acct_Container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.RCM_Aggr_Acct_List {
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.075);
    color: rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    /* width: 12rem; */
}

.RCM_Aggr_error_container {
    color: white;
    padding: 8px 0px;
    font-size: 12px;
    text-align: center;
    width: 100%;
    background-color: rgb(204, 7, 7);
}

.RCM_subAccount_toast {
    color: #fff;
}
/* End of rockefeller ninth waves custom component */
